export const GAME_TITLE = 'СЛІВЦЕ'

export const WIN_MESSAGES = ['Чудова робота!', 'Чудово', 'Молодець!']
export const GAME_COPIED_MESSAGE = 'Гра скопійована в буфер обміну'
export const ABOUT_GAME_MESSAGE = 'Про гру'
export const NOT_ENOUGH_LETTERS_MESSAGE = 'Недостатньо літер'
export const WORD_NOT_FOUND_MESSAGE = 'Слово не знайдене'
export const CORRECT_WORD_MESSAGE = (solution: string) =>
  `Правильне слово ${solution}`
export const ENTER_TEXT = 'Ввести'
export const DELETE_TEXT = 'Стерти'
export const STATISTICS_TITLE = 'Статистика'
export const GUESS_DISTRIBUTION_TEXT = 'Розподіл відгадувань'
export const NEW_WORD_TEXT = 'Нове слово через'
export const SHARE_TEXT = 'Поділитися'
export const TOTAL_TRIES_TEXT = 'Всього спроб'
export const SUCCESS_RATE_TEXT = 'Відсоток успіху'
export const CURRENT_STREAK_TEXT = 'Поточна серія'
export const BEST_STREAK_TEXT = 'Найкраща серія'
