import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="Як грати" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
      Вгадайте слово за 6 спроб. Після кожної відгадки колір плитки буде змінюватися,
      щоб показати, наскільки близькою до слова була ваша відгадка.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="С" status="correct" />
        <Cell value="Л" />
        <Cell value="О" />
        <Cell value="В" />
        <Cell value="О" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Літера С є у слові та знаходиться у правильному місці.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="К" />
        <Cell value="О" />
        <Cell value="Т" status="present" />
        <Cell value="И" />
        <Cell value="К" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Літера Т є у слові, але у неправильному місці.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="М" />
        <Cell value="О" />
        <Cell value="П" />
        <Cell value="Е" status="absent" />
        <Cell value="Д" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Літера Е відсутня у цьому слові.
      </p>
    </BaseModal>
  )
}
