export const VALIDGUESSES = [
  'абайя',
  'абвер',
  'абзац',
  'абияк',
  'аборт',
  'абрек',
  'абрис',
  'абцуг',
  'аваль',
  'аванс',
  'авгіт',
  'авгур',
  'авдит',
  'авеню',
  'аверс',
  'авізо',
  'авлос',
  'аврал',
  'авран',
  'автол',
  'автор',
  'агава',
  'агама',
  'агамі',
  'агапе',
  'агенс',
  'агент',
  'агент',
  'агнат',
  'агора',
  'аграф',
  'агусі',
  'агуті',
  'агуша',
  'аґрус',
  'аґусі',
  'адакс',
  'адепт',
  'адіть',
  'адмін',
  'адрон',
  'адукт',
  'аероб',
  'аерон',
  'ажгон',
  'азарт',
  'азиль',
  'айван',
  'аймак',
  'айпад',
  'айпод',
  'айран',
  'айфон',
  'акажу',
  'акант',
  'аканф',
  'акати',
  'акбар',
  'акіта',
  'акорд',
  'акрил',
  'аксис',
  'аксон',
  'актив',
  'актин',
  'актор',
  'акула',
  'акциз',
  'акція',
  'аларм',
  'алгол',
  'алель',
  'алича',
  'алібі',
  'алкан',
  'алкен',
  'алкіл',
  'алкін',
  'аллах',
  'алмаз',
  'алоза',
  'алонж',
  'алтей',
  'алтея',
  'алтин',
  'алунд',
  'альба',
  'альфа',
  'алярм',
  'амант',
  'амбар',
  'амбра',
  'амбре',
  'амвон',
  'амеба',
  'аміак',
  'амінь',
  'ампер',
  'ампір',
  'ампль',
  'амури',
  'анаша',
  'ангар',
  'ангел',
  'англи',
  'ангоб',
  'анзац',
  'аніже',
  'аніма',
  'аніме',
  'аніон',
  'аніяк',
  'анкер',
  'анонс',
  'антик',
  'антре',
  'антьє',
  'анумо',
  'ануте',
  'анфас',
  'анчар',
  'аорта',
  'апарт',
  'апекс',
  'апель',
  'аплет',
  'апное',
  'апорт',
  'апрет',
  'апрош',
  'апчхи',
  'арара',
  'аргат',
  'аргон',
  'аргус',
  'аргус',
  'арден',
  'ареал',
  'арена',
  'арешт',
  'аркан',
  'аркуш',
  'армія',
  'арсен',
  'арсин',
  'архар',
  'археї',
  'архів',
  'аршин',
  'асана',
  'аскер',
  'аскет',
  'аспид',
  'аспід',
  'аспід',
  'астат',
  'астма',
  'асура',
  'асцит',
  'атака',
  'аташе',
  'атлас',
  'атлет',
  'аттик',
  'аудит',
  'аудіо',
  'аурум',
  'афект',
  'афера',
  'афини',
  'афікс',
  'афіша',
  'ахати',
  'ашрам',
  'бабай',
  'бабак',
  'бабах',
  'бабин',
  'бабич',
  'бабій',
  'бабіт',
  'бабка',
  'бабка',
  'бабця',
  'бавно',
  'багаж',
  'багач',
  'багер',
  'багет',
  'багно',
  'багор',
  'баґґі',
  'бадан',
  'баддя',
  'базар',
  'базис',
  'байда',
  'байка',
  'бакай',
  'бакан',
  'бакен',
  'бакир',
  'бакун',
  'бакша',
  'балан',
  'балас',
  'балет',
  'балик',
  'балія',
  'балка',
  'балон',
  'бальї',
  'бамія',
  'банан',
  'банда',
  'бандо',
  'банер',
  'баніт',
  'банка',
  'банко',
  'банош',
  'банта',
  'бануш',
  'баняк',
  'бараж',
  'барак',
  'баран',
  'баран',
  'барба',
  'барва',
  'барда',
  'бареж',
  'баржа',
  'барит',
  'бариш',
  'барій',
  'барка',
  'барок',
  'барон',
  'басет',
  'басма',
  'басок',
  'басон',
  'баста',
  'бастр',
  'батат',
  'батик',
  'батир',
  'батіг',
  'батон',
  'батут',
  'батяр',
  'бахаї',
  'бахур',
  'бахші',
  'бачно',
  'бачок',
  'башта',
  'баюра',
  'баяті',
  'бгати',
  'бебех',
  'бебех',
  'бевзь',
  'беґас',
  'безум',
  'безус',
  'бейбі',
  'бейдж',
  'бейза',
  'бекар',
  'бекас',
  'бекет',
  'бекон',
  'беміт',
  'бенді',
  'бенто',
  'бердо',
  'берег',
  'берет',
  'берил',
  'берло',
  'берма',
  'берці',
  'бесур',
  'бетон',
  'бздур',
  'бидло',
  'бидля',
  'билля',
  'бинда',
  'бирка',
  'бирса',
  'бирфа',
  'битва',
  'битий',
  'битка',
  'биття',
  'битюг',
  'битюк',
  'бичий',
  'бичок',
  'бичок',
  'бишак',
  'біакс',
  'бівак',
  'бігль',
  'бігма',
  'бігма',
  'бігме',
  'бігом',
  'бігос',
  'бігти',
  'бігун',
  'бігун',
  'бігус',
  'біґос',
  'бідак',
  'бідар',
  'бідка',
  'бідон',
  'бізон',
  'бійка',
  'бійня',
  'білан',
  'білет',
  'білий',
  'білик',
  'білка',
  'білль',
  'білок',
  'білон',
  'білон',
  'біляк',
  'біляк',
  'біляр',
  'біляш',
  'бінго',
  'біном',
  'біонт',
  'біота',
  'біпер',
  'біржа',
  'бісер',
  'бісик',
  'бісів',
  'бістр',
  'бісус',
  'бітер',
  'бітум',
  'бішар',
  'благо',
  'благо',
  'бланк',
  'бланш',
  'близь',
  'блиск',
  'блись',
  'блінт',
  'блоха',
  'блуза',
  'блюдо',
  'бляск',
  'бляха',
  'бобер',
  'бобик',
  'бобир',
  'бобік',
  'бовть',
  'богів',
  'бодня',
  'бодхі',
  'бодян',
  'боєць',
  'божба',
  'божий',
  'божок',
  'бозон',
  'боїнг',
  'боїще',
  'бойня',
  'бойок',
  'бокаж',
  'бокал',
  'боком',
  'бокор',
  'болід',
  'болюс',
  'бомба',
  'бомки',
  'бонги',
  'бонза',
  'бонна',
  'бонус',
  'боран',
  'борат',
  'бордо',
  'борей',
  'борид',
  'борня',
  'борть',
  'борть',
  'босий',
  'босяк',
  'ботик',
  'бофон',
  'боцян',
  'бочка',
  'бочок',
  'брага',
  'брама',
  'бранч',
  'брань',
  'брати',
  'бреве',
  'брейк',
  'бренд',
  'брень',
  'бридж',
  'бридь',
  'брижа',
  'брила',
  'бриль',
  'бринь',
  'брити',
  'бритт',
  'бриця',
  'бріар',
  'бріош',
  'брова',
  'броги',
  'бронх',
  'бронь',
  'броня',
  'брухт',
  'брязк',
  'брязь',
  'бубка',
  'бубон',
  'бубон',
  'бубух',
  'бугай',
  'будда',
  'будка',
  'будяк',
  'бузок',
  'буйок',
  'буква',
  'букер',
  'букет',
  'букле',
  'букля',
  'букса',
  'булат',
  'булат',
  'булий',
  'булів',
  'буліт',
  'булка',
  'булла',
  'бульк',
  'бунда',
  'бундз',
  'бурав',
  'буран',
  'бурат',
  'бурда',
  'бурий',
  'бурка',
  'бурса',
  'бурта',
  'бурун',
  'буряк',
  'бусел',
  'бусик',
  'бусол',
  'бутан',
  'бутер',
  'бутик',
  'бутил',
  'бутин',
  'бутит',
  'бутон',
  'буття',
  'бутут',
  'буфер',
  'буфет',
  'буфет',
  'буфон',
  'бухан',
  'бухта',
  'буцик',
  'бучок',
  'буяти',
  'буяхи',
  'бювар',
  'бювет',
  'бюкса',
  'бюрко',
  'вабик',
  'вавка',
  'вагар',
  'вагон',
  'вагус',
  'вадія',
  'важко',
  'важно',
  'важок',
  'вазон',
  'вайда',
  'вайло',
  'вакар',
  'вакат',
  'вакса',
  'вакуф',
  'валер',
  'валет',
  'валик',
  'валик',
  'валіа',
  'валін',
  'валка',
  'валок',
  'валом',
  'валун',
  'валух',
  'вальс',
  'валюш',
  'вамбі',
  'ванна',
  'ванна',
  'ванта',
  'вапно',
  'варан',
  'варга',
  'варка',
  'варко',
  'варна',
  'варта',
  'варто',
  'варус',
  'варяг',
  'васаг',
  'васал',
  'ватаг',
  'ватер',
  'ватин',
  'ватка',
  'ватра',
  'вафля',
  'вахня',
  'вахта',
  'вбити',
  'вбрід',
  'вбути',
  'вверх',
  'ввись',
  'вволю',
  'вглиб',
  'вгорі',
  'вгору',
  'вдати',
  'вдача',
  'вдень',
  'вдіти',
  'вдова',
  'вдома',
  'вдути',
  'вдяка',
  'вебер',
  'веган',
  'вежка',
  'везир',
  'везір',
  'везти',
  'везун',
  'векша',
  'велет',
  'велич',
  'велум',
  'вельд',
  'велюр',
  'венед',
  'венет',
  'вента',
  'вепер',
  'верба',
  'верва',
  'веред',
  'верес',
  'верея',
  'верже',
  'верло',
  'верть',
  'верхи',
  'верша',
  'весло',
  'весна',
  'вессі',
  'вести',
  'вечір',
  'вжити',
  'взвод',
  'взимі',
  'взути',
  'взяти',
  'вибіг',
  'вибій',
  'вибіл',
  'вибір',
  'вибух',
  'вивар',
  'вивих',
  'вивід',
  'вивід',
  'вивіз',
  'вигад',
  'вигар',
  'вигин',
  'вигін',
  'вигук',
  'вигул',
  'видих',
  'виділ',
  'видко',
  'видма',
  'видно',
  'видра',
  'вижга',
  'вижим',
  'вижин',
  'виїзд',
  'вийна',
  'вийти',
  'виказ',
  'викид',
  'викоп',
  'викос',
  'викот',
  'викуп',
  'вилаз',
  'вилив',
  'вилім',
  'виліт',
  'вилка',
  'вилка',
  'вилки',
  'вилов',
  'вилом',
  'вимах',
  'вимін',
  'вимір',
  'винар',
  'винно',
  'винос',
  'винце',
  'випад',
  'випал',
  'випар',
  'випас',
  'випин',
  'випис',
  'випит',
  'випіт',
  'вираз',
  'вирва',
  'виріб',
  'вирід',
  'виріз',
  'вирій',
  'вирла',
  'вирок',
  'вирок',
  'вируб',
  'виряд',
  'висад',
  'висип',
  'висів',
  'висок',
  'виспа',
  'витий',
  'витин',
  'витік',
  'виток',
  'витоп',
  'виття',
  'витяг',
  'вихід',
  'вихор',
  'вихор',
  'вишар',
  'вишка',
  'вишня',
  'вищип',
  'вищир',
  'віват',
  'вівця',
  'відео',
  'відро',
  'відси',
  'відун',
  'віжка',
  'візаж',
  'візир',
  'візир',
  'візит',
  'візія',
  'візок',
  'війка',
  'війна',
  'війце',
  'вікно',
  'віліс',
  'вілла',
  'віник',
  'вініл',
  'вінок',
  'вінол',
  'вінце',
  'вінця',
  'віола',
  'віраж',
  'вірля',
  'вірні',
  'вірно',
  'віроз',
  'вірус',
  'віскі',
  'віспа',
  'вість',
  'вітер',
  'вітка',
  'віття',
  'вічко',
  'вішак',
  'вішка',
  'віштя',
  'віщий',
  'віщун',
  'віяло',
  'віяти',
  'вклад',
  'вкрай',
  'вкруг',
  'вкупі',
  'вкупу',
  'влада',
  'влити',
  'вліво',
  'вліті',
  'влови',
  'вляги',
  'вмент',
  'вмити',
  'вмить',
  'вміст',
  'вміти',
  'внизу',
  'вночі',
  'внуча',
  'вобла',
  'вовік',
  'вовна',
  'вовча',
  'вогко',
  'водій',
  'водно',
  'вожак',
  'вождь',
  'возик',
  'возій',
  'вокал',
  'волан',
  'волга',
  'волик',
  'волок',
  'волос',
  'волот',
  'волох',
  'волхв',
  'вольт',
  'воляр',
  'воляр',
  'ворог',
  'ворок',
  'ворон',
  'ворон',
  'ворох',
  'ворса',
  'вотум',
  'вохра',
  'вошва',
  'вошка',
  'вояка',
  'впень',
  'вперш',
  'впити',
  'вплав',
  'вплач',
  'вплив',
  'вплин',
  'впору',
  'впуск',
  'враза',
  'врата',
  'врити',
  'врода',
  'вроки',
  'вруно',
  'вскач',
  'вслід',
  'всмак',
  'всоте',
  'вспак',
  'вспід',
  'встид',
  'встик',
  'вступ',
  'встяж',
  'всуху',
  'всюди',
  'всяко',
  'втеча',
  'втиск',
  'втіха',
  'втома',
  'втора',
  'втяти',
  'вуаль',
  'вуаля',
  'вугор',
  'вугор',
  'вудка',
  'вужак',
  'вужик',
  'вужів',
  'вузда',
  'вузол',
  'вуйко',
  'вулик',
  'вулій',
  'вумен',
  'вурда',
  'вусач',
  'вусик',
  'вусок',
  'вуста',
  'вушко',
  'вчвал',
  'вчити',
  'вчора',
  'вчути',
  'вширш',
  'вшити',
  'вщент',
  'габіт',
  'габро',
  'гавот',
  'гагат',
  'гаддя',
  'гадес',
  'гадів',
  'гадка',
  'газик',
  'газон',
  'гайда',
  'гайка',
  'гайно',
  'гайно',
  'гайок',
  'галас',
  'галат',
  'гален',
  'галич',
  'галід',
  'галій',
  'галіт',
  'галка',
  'галон',
  'галоп',
  'галун',
  'галун',
  'галча',
  'гамак',
  'гаман',
  'гамба',
  'гамір',
  'гамма',
  'гамон',
  'гамуз',
  'ганаш',
  'ганус',
  'гапка',
  'гараж',
  'гарба',
  'гарда',
  'гарем',
  'гарне',
  'гарно',
  'гарус',
  'гасло',
  'гатка',
  'гауда',
  'гаучо',
  'гачок',
  'гашиш',
  'гаяти',
  'гвинт',
  'гевея',
  'гегеп',
  'геєна',
  'гейша',
  'гекон',
  'гелер',
  'гелій',
  'геліт',
  'гемін',
  'гемон',
  'генде',
  'генез',
  'геній',
  'геній',
  'геном',
  'генрі',
  'геоїд',
  'герма',
  'герой',
  'герць',
  'гетер',
  'гетит',
  'гетра',
  'гетто',
  'гжель',
  'гзимс',
  'гидко',
  'гилка',
  'гилля',
  'гилун',
  'гилун',
  'гирка',
  'гирло',
  'гичка',
  'гібон',
  'гідра',
  'гієна',
  'гілка',
  'гілля',
  'гімен',
  'гінея',
  'гінка',
  'гіпюр',
  'гірка',
  'гірка',
  'гірко',
  'гісоп',
  'гість',
  'гічка',
  'глава',
  'глава',
  'главк',
  'гладь',
  'гласе',
  'глива',
  'глина',
  'глист',
  'глиця',
  'гліпт',
  'глоба',
  'глоса',
  'глось',
  'глузд',
  'глузи',
  'глупа',
  'глухо',
  'глюон',
  'глядь',
  'глянс',
  'глянц',
  'глясе',
  'гміна',
  'гнати',
  'гнейс',
  'гнида',
  'гниль',
  'гнити',
  'гнояк',
  'гнути',
  'гобіт',
  'гобой',
  'говір',
  'гогіт',
  'годжі',
  'годок',
  'годун',
  'гожий',
  'гоїти',
  'гойда',
  'гойно',
  'голий',
  'голий',
  'голиш',
  'голиш',
  'голка',
  'голод',
  'голос',
  'голуб',
  'голуб',
  'гольф',
  'гольф',
  'голяк',
  'голяк',
  'голяр',
  'гомін',
  'гомоз',
  'гоніт',
  'гонок',
  'гонор',
  'гонта',
  'гонча',
  'гопак',
  'гопки',
  'гопля',
  'горіх',
  'горло',
  'горно',
  'горня',
  'город',
  'горор',
  'горох',
  'горою',
  'горст',
  'горща',
  'гостя',
  'гофре',
  'гоцак',
  'граве',
  'гранд',
  'гранж',
  'грант',
  'грань',
  'грата',
  'грати',
  'графа',
  'грежа',
  'грена',
  'грець',
  'грець',
  'грива',
  'гридь',
  'грижа',
  'гриль',
  'грище',
  'гріти',
  'гроза',
  'гроно',
  'гроші',
  'груба',
  'груда',
  'груди',
  'грудь',
  'грузд',
  'група',
  'груша',
  'гряда',
  'грязь',
  'гуано',
  'губка',
  'гугіт',
  'гугуп',
  'гудок',
  'гужем',
  'гузир',
  'гузка',
  'гузно',
  'гулаг',
  'гулий',
  'гулко',
  'гульк',
  'гуляш',
  'гумай',
  'гуміт',
  'гумка',
  'гумоз',
  'гумор',
  'гумус',
  'гуппі',
  'гурія',
  'гурка',
  'гурра',
  'гусак',
  'гусак',
  'гусар',
  'гусит',
  'гусій',
  'гуска',
  'гусла',
  'гуслі',
  'густи',
  'гуцул',
  'гучно',
  'гучок',
  'гущак',
  'гюрза',
  'ґаблі',
  'ґавин',
  'ґазда',
  'ґалій',
  'ґандж',
  'ґандж',
  'ґанок',
  'ґвалт',
  'ґевал',
  'ґедзь',
  'ґзимс',
  'ґміна',
  'ґонта',
  'ґрата',
  'ґрати',
  'ґроно',
  'ґрунт',
  'ґуґля',
  'ґудзь',
  'давач',
  'давно',
  'даєць',
  'дайка',
  'дайна',
  'далеч',
  'далія',
  'дамба',
  'дамка',
  'даний',
  'дання',
  'дарма',
  'дартс',
  'дарча',
  'датив',
  'даток',
  'дацит',
  'дачка',
  'дашок',
  'дбати',
  'дбаха',
  'двері',
  'двічі',
  'двояк',
  'дебай',
  'дебет',
  'дебіл',
  'дебіт',
  'дебют',
  'девіз',
  'девон',
  'деїзм',
  'деїст',
  'декан',
  'декор',
  'демон',
  'демос',
  'денді',
  'денім',
  'денце',
  'деньє',
  'дербі',
  'дерен',
  'дерен',
  'дерик',
  'дерій',
  'дерма',
  'дерти',
  'дерть',
  'дертя',
  'дерун',
  'дерун',
  'десть',
  'дефіс',
  'дечий',
  'джайв',
  'джбан',
  'джгут',
  'джига',
  'джума',
  'джура',
  'дзбан',
  'дзвін',
  'дзвін',
  'дзвяк',
  'дзвяк',
  'дзень',
  'дзета',
  'дзиґа',
  'дзиґа',
  'дзинь',
  'дзінь',
  'дзусь',
  'дзьоб',
  'дзюба',
  'дзюдо',
  'дибка',
  'дибки',
  'дибки',
  'дибом',
  'дивак',
  'диван',
  'диван',
  'дивно',
  'дигер',
  'дикий',
  'дикун',
  'дилер',
  'димар',
  'димер',
  'димка',
  'димок',
  'динар',
  'динас',
  'динго',
  'динод',
  'диско',
  'дичар',
  'дичка',
  'діада',
  'дівер',
  'дівка',
  'дівча',
  'дідик',
  'дідик',
  'дідич',
  'дідів',
  'дідок',
  'дідух',
  'дієта',
  'діжка',
  'дійво',
  'дійка',
  'дійна',
  'дійти',
  'ділок',
  'дімок',
  'дірка',
  'дітва',
  'дітки',
  'діяти',
  'днесь',
  'днина',
  'днище',
  'дніти',
  'днюха',
  'днями',
  'добич',
  'добір',
  'добре',
  'добре',
  'добро',
  'довід',
  'довіз',
  'догма',
  'доджо',
  'дожин',
  'дожів',
  'дозір',
  'дозор',
  'доїзд',
  'доїти',
  'дойда',
  'дойна',
  'доказ',
  'докер',
  'докір',
  'долар',
  'долма',
  'долок',
  'долом',
  'домен',
  'домна',
  'домра',
  'донин',
  'донка',
  'донна',
  'донор',
  'донос',
  'донья',
  'допис',
  'допит',
  'дорош',
  'досів',
  'досіл',
  'досьє',
  'дотеп',
  'дотик',
  'дотла',
  'дофін',
  'дохід',
  'дочка',
  'дошка',
  'дощик',
  'драга',
  'драже',
  'драйв',
  'драла',
  'драма',
  'дрань',
  'драти',
  'драфт',
  'древо',
  'дреди',
  'дрейф',
  'дрена',
  'дриль',
  'дриля',
  'дрізд',
  'дрова',
  'дроги',
  'дрозд',
  'друге',
  'друза',
  'друїд',
  'дуаєн',
  'дуант',
  'дубль',
  'дубок',
  'дувал',
  'дудак',
  'дудар',
  'дудка',
  'дудук',
  'дуель',
  'дужий',
  'дужка',
  'дукар',
  'дукат',
  'дукач',
  'дукач',
  'дуліб',
  'дулко',
  'думка',
  'думно',
  'дунст',
  'дупка',
  'дупло',
  'дупця',
  'дурак',
  'дурка',
  'дурне',
  'дурно',
  'дурня',
  'дурра',
  'дутар',
  'дутий',
  'дутик',
  'дутик',
  'дуття',
  'дууже',
  'духан',
  'дучка',
  'душка',
  'душно',
  'душок',
  'дюбек',
  'дюкер',
  'дюшес',
  'дядин',
  'дядьо',
  'дяків',
  'дятел',
  'дячок',
  'ебола',
  'егіда',
  'егрет',
  'едема',
  'едикт',
  'ейдос',
  'екати',
  'екзил',
  'екзон',
  'екзот',
  'еклер',
  'екран',
  'ексон',
  'елінг',
  'еліпс',
  'еліта',
  'еллін',
  'елюат',
  'емаль',
  'ембол',
  'енант',
  'ендек',
  'ендем',
  'ендси',
  'ензим',
  'енний',
  'еозин',
  'еоліт',
  'еоліт',
  'еоцен',
  'епарх',
  'епіка',
  'епонж',
  'епоха',
  'епюра',
  'ербій',
  'ерзац',
  'еркер',
  'есдек',
  'ескіз',
  'естер',
  'естет',
  'етвеш',
  'етика',
  'етнос',
  'етрол',
  'ефект',
  'ефель',
  'євнух',
  'єврей',
  'єврик',
  'єдваб',
  'єзуїт',
  'ємний',
  'єпарх',
  'єресь',
  'єство',
  'єхида',
  'жабка',
  'жабка',
  'жабня',
  'жабра',
  'жадак',
  'жакан',
  'жакет',
  'жалон',
  'жарко',
  'жарок',
  'жатий',
  'жатка',
  'жаття',
  'ждані',
  'ждати',
  'жебри',
  'желяр',
  'жених',
  'жеода',
  'жердь',
  'жердя',
  'жереб',
  'жереп',
  'жерех',
  'жерло',
  'жерти',
  'жертя',
  'жерун',
  'жетон',
  'живий',
  'живіт',
  'жидва',
  'жидик',
  'жидів',
  'жидок',
  'жижки',
  'жилет',
  'жилий',
  'жилка',
  'жират',
  'жираф',
  'жирне',
  'жирно',
  'жирок',
  'жирун',
  'житво',
  'житіє',
  'житка',
  'житло',
  'життя',
  'житце',
  'жінка',
  'жмаки',
  'жменя',
  'жмихи',
  'жмудь',
  'жмури',
  'жнець',
  'жнива',
  'жниво',
  'жниця',
  'жовна',
  'жовно',
  'жовто',
  'жокей',
  'жолоб',
  'жопка',
  'жорно',
  'жрець',
  'жриця',
  'жуйка',
  'жуйні',
  'жупан',
  'жупел',
  'журба',
  'журек',
  'журно',
  'жучок',
  'жучок',
  'забаг',
  'забіг',
  'забій',
  'забіл',
  'забір',
  'завал',
  'завід',
  'завіз',
  'завій',
  'завіт',
  'завія',
  'завод',
  'завуч',
  'завше',
  'загад',
  'загал',
  'загар',
  'загин',
  'загін',
  'загул',
  'задих',
  'задок',
  'задом',
  'задум',
  'заєць',
  'зажин',
  'зазор',
  'зазуб',
  'заїда',
  'заїзд',
  'заїка',
  'зайве',
  'зайво',
  'зайда',
  'зайти',
  'зайча',
  'закид',
  'закис',
  'закіп',
  'закон',
  'закот',
  'закуп',
  'закут',
  'залік',
  'залім',
  'заліт',
  'залка',
  'залом',
  'замах',
  'замет',
  'заміж',
  'замір',
  'заміс',
  'замок',
  'замок',
  'замор',
  'замша',
  'заніз',
  'занос',
  'заочі',
  'запал',
  'запас',
  'запах',
  'запин',
  'запис',
  'запит',
  'запій',
  'запіл',
  'запір',
  'запор',
  'зараз',
  'зарва',
  'зарин',
  'зарис',
  'заріз',
  'зарок',
  'заруб',
  'заряд',
  'засіб',
  'засів',
  'засік',
  'засіл',
  'заспа',
  'засув',
  'затин',
  'затір',
  'затіс',
  'затон',
  'затор',
  'захід',
  'захов',
  'зачин',
  'зачіп',
  'зачіс',
  'заява',
  'збити',
  'збіса',
  'збоку',
  'збори',
  'зброя',
  'збруя',
  'збути',
  'зваба',
  'звага',
  'звада',
  'звати',
  'звити',
  'звище',
  'звіку',
  'звіря',
  'згага',
  'згірш',
  'згода',
  'згори',
  'зграя',
  'згуба',
  'згуда',
  'здаля',
  'здати',
  'здача',
  'здвиг',
  'здоба',
  'здуру',
  'здути',
  'зебра',
  'зебра',
  'зелот',
  'зельц',
  'земля',
  'земно',
  'зеніт',
  'зерно',
  'зернь',
  'зерня',
  'зефір',
  'зечка',
  'зжити',
  'ззаду',
  'зизий',
  'зизом',
  'зилот',
  'зимно',
  'зимою',
  'зійти',
  'зілля',
  'зірка',
  'зірка',
  'зіяти',
  'злива',
  'злити',
  'злити',
  'зліва',
  'злоба',
  'злото',
  'злука',
  'злюка',
  'зляку',
  'змалу',
  'змити',
  'зміїв',
  'зміна',
  'зміст',
  'змова',
  'змога',
  'змора',
  'знада',
  'знано',
  'знати',
  'знать',
  'знизу',
  'знову',
  'зночі',
  'зняти',
  'зобик',
  'зовні',
  'зозла',
  'зоман',
  'зомбі',
  'зошит',
  'зрада',
  'зраза',
  'зрази',
  'зразу',
  'зрана',
  'зрити',
  'зріло',
  'зріст',
  'зріти',
  'зріти',
  'зроду',
  'зубик',
  'зубок',
  'зубря',
  'зудар',
  'зулус',
  'зумба',
  'зумер',
  'зумпф',
  'зупин',
  'зурна',
  'зшити',
  'зятів',
  'зяяти',
  'івасі',
  'іврит',
  'ігрек',
  'ідеал',
  'іджма',
  'ідіот',
  'ієрей',
  'іжиця',
  'ізвод',
  'ізгой',
  'ізнов',
  'ізюбр',
  'ікати',
  'ікона',
  'іксія',
  'ілеус',
  'імаго',
  'імаго',
  'імаго',
  'імбир',
  'імідж',
  'імпет',
  'інако',
  'інвар',
  'інгуш',
  'індик',
  'індій',
  'індол',
  'індус',
  'інжир',
  'інкуб',
  'іноді',
  'інтим',
  'інфіз',
  'іоніт',
  'іонол',
  'іприт',
  'ірбіс',
  'ірмос',
  'іскра',
  'іслам',
  'іспит',
  'істик',
  'ітися',
  'ітрій',
  'іудей',
  'іудин',
  'ішіас',
  'ішхан',
  'їдало',
  'їдати',
  'їдець',
  'їдкий',
  'їдуха',
  'їдьма',
  'їздак',
  'їздка',
  'їздун',
  'їстки',
  'їхати',
  'їхній',
  'йняти',
  'йодат',
  'йодид',
  'йодил',
  'йодит',
  'йодль',
  'йолоп',
  'йомен',
  'йтися',
  'кабак',
  'кабан',
  'кабан',
  'кабза',
  'кавер',
  'кавун',
  'кагал',
  'каган',
  'кагат',
  'кагла',
  'кагор',
  'кадет',
  'кадик',
  'кадіб',
  'кадуб',
  'кадук',
  'кадук',
  'кажан',
  'казан',
  'казка',
  'казна',
  'казус',
  'кайло',
  'кайма',
  'кайра',
  'какао',
  'калан',
  'калач',
  'калга',
  'калим',
  'калій',
  'каліф',
  'каліч',
  'калюс',
  'камео',
  'камея',
  'камза',
  'камін',
  'камка',
  'кампф',
  'камса',
  'канал',
  'канат',
  'канва',
  'канна',
  'канна',
  'каное',
  'канон',
  'канюк',
  'капар',
  'капер',
  'капіж',
  'капля',
  'капок',
  'капор',
  'капот',
  'каппа',
  'капут',
  'карат',
  'карга',
  'карго',
  'карда',
  'карий',
  'карий',
  'карія',
  'карма',
  'карст',
  'карта',
  'карук',
  'карус',
  'карюк',
  'касир',
  'касія',
  'каска',
  'каско',
  'каста',
  'катар',
  'катер',
  'катет',
  'катик',
  'катів',
  'катма',
  'катод',
  'каток',
  'каурі',
  'каурі',
  'кафір',
  'кахля',
  'кацап',
  'качан',
  'качка',
  'качур',
  'кашка',
  'кашне',
  'кашпо',
  'каюта',
  'квага',
  'квант',
  'кварк',
  'кварц',
  'кваша',
  'квест',
  'квити',
  'квіти',
  'квота',
  'кебаб',
  'кегль',
  'кегля',
  'келар',
  'келеп',
  'келех',
  'келих',
  'келія',
  'кельт',
  'кендо',
  'кендя',
  'кепка',
  'керма',
  'кермо',
  'кесар',
  'кесон',
  'кетоз',
  'кетон',
  'кетяг',
  'кефір',
  'кивок',
  'кидок',
  'кизил',
  'кийок',
  'килим',
  'кирея',
  'кирза',
  'кирка',
  'кирпа',
  'кирпа',
  'кисет',
  'кисле',
  'кисло',
  'кисть',
  'кицин',
  'кишка',
  'кишло',
  'киюра',
  'кібуц',
  'ківер',
  'кізка',
  'кізяк',
  'кілер',
  'кілік',
  'кілля',
  'кілок',
  'кімчі',
  'кінва',
  'кінза',
  'кінік',
  'кінно',
  'кіноа',
  'кіоск',
  'кіпіш',
  'кіпка',
  'кірка',
  'кірха',
  'кіска',
  'кісся',
  'кіста',
  'кість',
  'кітва',
  'кітна',
  'кіфоз',
  'кішка',
  'кішка',
  'кладь',
  'кларк',
  'клатч',
  'клема',
  'клерк',
  'клиша',
  'кліка',
  'клінч',
  'кліпс',
  'кліть',
  'кліше',
  'клоун',
  'клуня',
  'кльов',
  'кльош',
  'кльош',
  'клюка',
  'кметь',
  'кнага',
  'кнель',
  'кнехт',
  'книга',
  'княжа',
  'князь',
  'коала',
  'коата',
  'коаті',
  'кобза',
  'кобиз',
  'кобоз',
  'кобра',
  'кобуз',
  'ковід',
  'ковть',
  'кодек',
  'кодер',
  'кодер',
  'кодло',
  'кодон',
  'коєць',
  'кожух',
  'козак',
  'козар',
  'козел',
  'козел',
  'козир',
  'козла',
  'козли',
  'козля',
  'козуб',
  'коїти',
  'койка',
  'койот',
  'кокон',
  'кокос',
  'кокус',
  'колаж',
  'колар',
  'колба',
  'колеж',
  'колір',
  'коліт',
  'колія',
  'колон',
  'колон',
  'колос',
  'колос',
  'колун',
  'кольє',
  'кольт',
  'колюр',
  'комар',
  'комбі',
  'комбо',
  'комин',
  'комиш',
  'комік',
  'комір',
  'комод',
  'конак',
  'конар',
  'коник',
  'коник',
  'конка',
  'конто',
  'конус',
  'конче',
  'конюх',
  'коняр',
  'копач',
  'копач',
  'копер',
  'копил',
  'копір',
  'копія',
  'копня',
  'копра',
  'корал',
  'корал',
  'корба',
  'коргі',
  'корма',
  'короб',
  'корок',
  'короп',
  'коряк',
  'косар',
  'косий',
  'косяк',
  'косяк',
  'котел',
  'котик',
  'котик',
  'котко',
  'коток',
  'коток',
  'котон',
  'котун',
  'кофта',
  'коцик',
  'кочет',
  'кошик',
  'кошма',
  'кощій',
  'кпити',
  'крага',
  'краля',
  'краса',
  'крафт',
  'краще',
  'кредо',
  'креси',
  'кресь',
  'крива',
  'крига',
  'крижі',
  'криза',
  'крило',
  'криль',
  'криля',
  'криси',
  'крити',
  'криця',
  'кріль',
  'кроль',
  'кроля',
  'крона',
  'круїз',
  'крумк',
  'крупа',
  'круто',
  'круть',
  'круча',
  'кряча',
  'кубик',
  'кубіт',
  'кубло',
  'кубок',
  'кувез',
  'кугут',
  'кудла',
  'кудла',
  'кудли',
  'кудою',
  'кузен',
  'кузня',
  'кузов',
  'кукан',
  'кукса',
  'кукса',
  'кулак',
  'кулан',
  'кулер',
  'кулик',
  'кулик',
  'куліш',
  'кулон',
  'культ',
  'куман',
  'куман',
  'кумач',
  'кумжа',
  'кумин',
  'кумир',
  'кумир',
  'кумис',
  'кумів',
  'кумка',
  'кумця',
  'кунак',
  'кунфу',
  'куншт',
  'купаж',
  'купер',
  'купка',
  'купки',
  'купля',
  'купно',
  'купол',
  'купон',
  'кураж',
  'курай',
  'курай',
  'курак',
  'курій',
  'курія',
  'курка',
  'курли',
  'курок',
  'курча',
  'курча',
  'кусок',
  'кутас',
  'кутий',
  'кутик',
  'кутин',
  'куток',
  'кутюр',
  'кухар',
  'кухля',
  'кухня',
  'куцак',
  'куцак',
  'куцан',
  'куций',
  'кучер',
  'кучер',
  'кучма',
  'кушир',
  'кушка',
  'кущик',
  'кьошк',
  'кювет',
  'кюрит',
  'кюрій',
  'кяриз',
  'лабаз',
  'лабза',
  'лабка',
  'лаваш',
  'лавіс',
  'лавка',
  'лавра',
  'лагер',
  'ладан',
  'лазар',
  'лазер',
  'лазій',
  'лазня',
  'лазок',
  'лазун',
  'лазур',
  'лайба',
  'лайда',
  'лайка',
  'лайка',
  'лайно',
  'лакей',
  'ламач',
  'лампа',
  'ланди',
  'ландо',
  'ланка',
  'ланок',
  'ланос',
  'лапка',
  'лапки',
  'ларга',
  'ларго',
  'ласий',
  'ласка',
  'ласка',
  'ласун',
  'латач',
  'латин',
  'латка',
  'лаття',
  'латук',
  'лауда',
  'лаунж',
  'лафет',
  'лафіт',
  'лахта',
  'лаяти',
  'левіт',
  'легат',
  'легат',
  'легіт',
  'легко',
  'ледар',
  'ледач',
  'лежак',
  'лежма',
  'лежун',
  'лезво',
  'лейка',
  'лекіф',
  'лелія',
  'леміш',
  'лемур',
  'ленто',
  'лепет',
  'лепра',
  'лепта',
  'лерка',
  'летун',
  'ливар',
  'лижва',
  'лижня',
  'лизун',
  'лилик',
  'лиман',
  'лимар',
  'лимон',
  'линва',
  'линок',
  'линча',
  'липка',
  'лисак',
  'лисий',
  'лисий',
  'лиска',
  'листя',
  'лисун',
  'литва',
  'литво',
  'литий',
  'литія',
  'литка',
  'лиття',
  'лихач',
  'лихва',
  'лихий',
  'лицар',
  'личак',
  'личко',
  'лишай',
  'лишка',
  'лишок',
  'лищак',
  'ліана',
  'лібра',
  'лівак',
  'лівар',
  'лівер',
  'лівер',
  'лівий',
  'лівіш',
  'лівша',
  'лігар',
  'лігво',
  'лігма',
  'лідар',
  'лідер',
  'лідит',
  'ліжко',
  'ліжма',
  'лізат',
  'лізин',
  'лізис',
  'лізка',
  'лізол',
  'лізти',
  'лійка',
  'лікар',
  'лікер',
  'лілея',
  'лілія',
  'лімбо',
  'ліміт',
  'лімфа',
  'лінза',
  'лінія',
  'лінон',
  'лінюх',
  'ліпід',
  'лірик',
  'ліска',
  'лісна',
  'лісок',
  'лісун',
  'літак',
  'літій',
  'літія',
  'літом',
  'літун',
  'ліцей',
  'ліція',
  'лічба',
  'лляти',
  'лобас',
  'лобик',
  'лобіо',
  'лобок',
  'лобур',
  'ловля',
  'логік',
  'логон',
  'логос',
  'лодія',
  'ложка',
  'лойок',
  'локон',
  'локус',
  'локша',
  'ломик',
  'ломка',
  'лонжа',
  'лопар',
  'лопар',
  'лопіт',
  'лопух',
  'лотік',
  'лоток',
  'лотос',
  'лохії',
  'лоція',
  'лошак',
  'лубок',
  'лудан',
  'лудан',
  'лужок',
  'лузга',
  'луніт',
  'лунка',
  'лунка',
  'лунко',
  'лупак',
  'лупій',
  'лупка',
  'луска',
  'лутка',
  'луфар',
  'лучка',
  'лучок',
  'лущик',
  'льоля',
  'льоха',
  'любас',
  'любий',
  'любка',
  'любка',
  'любко',
  'любов',
  'люгер',
  'людно',
  'людці',
  'люмен',
  'люнет',
  'люпин',
  'лютар',
  'лютий',
  'лютий',
  'лютня',
  'люшня',
  'лягти',
  'лямка',
  'ляпас',
  'ляпіс',
  'ляпка',
  'лярва',
  'ляхва',
  'лячно',
  'ляшок',
  'лящик',
  'мавка',
  'мавпа',
  'магія',
  'магма',
  'магог',
  'магот',
  'мадам',
  'маєво',
  'мажор',
  'мажор',
  'мазер',
  'мазій',
  'мазка',
  'мазок',
  'мазун',
  'мазут',
  'майво',
  'майже',
  'майка',
  'майка',
  'майна',
  'майно',
  'майор',
  'макак',
  'макао',
  'макет',
  'маків',
  'максі',
  'малат',
  'малий',
  'малка',
  'малку',
  'малюк',
  'маляр',
  'маляр',
  'маляс',
  'маман',
  'мамба',
  'мамбо',
  'мамин',
  'мамій',
  'мамка',
  'мамут',
  'мамця',
  'манат',
  'манга',
  'манго',
  'манеж',
  'манір',
  'манія',
  'манка',
  'манна',
  'манта',
  'манта',
  'манто',
  'манту',
  'манул',
  'марал',
  'маржа',
  'марка',
  'марля',
  'марне',
  'масаж',
  'масив',
  'маска',
  'масло',
  'масно',
  'масон',
  'масть',
  'матка',
  'матка',
  'матня',
  'матюк',
  'мафін',
  'мафія',
  'махра',
  'мацак',
  'мачок',
  'маяти',
  'меблі',
  'мегом',
  'медик',
  'медіа',
  'медії',
  'медок',
  'медок',
  'мезга',
  'мезон',
  'мейоз',
  'мекка',
  'мелан',
  'мелон',
  'мелос',
  'мелун',
  'мерва',
  'мерин',
  'мерія',
  'мерсі',
  'мерти',
  'месія',
  'мести',
  'месьє',
  'метал',
  'метан',
  'метил',
  'метис',
  'метка',
  'метод',
  'метол',
  'метоп',
  'метро',
  'меццо',
  'мечет',
  'мечик',
  'мжити',
  'мжиця',
  'мизка',
  'мийка',
  'мийна',
  'мийня',
  'милий',
  'милий',
  'мимря',
  'мирра',
  'мирта',
  'мисик',
  'миска',
  'мисль',
  'митар',
  'митий',
  'митра',
  'миттю',
  'миття',
  'мичка',
  'мишак',
  'мишва',
  'мишій',
  'мишка',
  'мишка',
  'міазм',
  'мігма',
  'мідія',
  'мідяк',
  'мідяр',
  'мізер',
  'мізер',
  'мізки',
  'мізюк',
  'мікоз',
  'мікст',
  'мілке',
  'мінер',
  'мінет',
  'мінея',
  'мінор',
  'мінор',
  'мінус',
  'міома',
  'міраж',
  'мірза',
  'мірка',
  'мірра',
  'місис',
  'місіс',
  'місія',
  'місто',
  'місце',
  'мітка',
  'мітла',
  'мітоз',
  'міхур',
  'міцно',
  'мішма',
  'мішма',
  'мішок',
  'мливо',
  'мліти',
  'ммоль',
  'мниха',
  'мовно',
  'могар',
  'могер',
  'могол',
  'могул',
  'модем',
  'модно',
  'модус',
  'можна',
  'мозок',
  'мойва',
  'мойра',
  'мойра',
  'моква',
  'мокко',
  'мокро',
  'молот',
  'молох',
  'моляр',
  'монах',
  'моном',
  'мопед',
  'морда',
  'морзе',
  'моріг',
  'мороз',
  'морок',
  'моряк',
  'мосьє',
  'мосяж',
  'мотет',
  'мотив',
  'моток',
  'мотор',
  'мотто',
  'мотуз',
  'мохер',
  'моцак',
  'моція',
  'мочар',
  'мочка',
  'мошва',
  'мошка',
  'мошка',
  'мрець',
  'мріти',
  'мрука',
  'мряка',
  'мугир',
  'мудак',
  'мужва',
  'мужик',
  'музей',
  'музин',
  'мулат',
  'муліт',
  'мулко',
  'мулла',
  'мульт',
  'муляж',
  'муляр',
  'муміє',
  'мумія',
  'мурал',
  'мурза',
  'мурий',
  'мурин',
  'мурло',
  'мурло',
  'мусон',
  'мутра',
  'муфля',
  'муфта',
  'муцик',
  'мушва',
  'мушка',
  'мушка',
  'мушля',
  'мчати',
  'мюзет',
  'мюзет',
  'мюрид',
  'мюслі',
  'набат',
  'набіг',
  'набій',
  'набік',
  'набір',
  'набір',
  'набла',
  'набоб',
  'навал',
  'навар',
  'навід',
  'навік',
  'навіс',
  'навіч',
  'нагай',
  'наган',
  'нагар',
  'нагий',
  'нагин',
  'нагін',
  'нагул',
  'надир',
  'надих',
  'надій',
  'наділ',
  'надія',
  'надра',
  'надто',
  'нажив',
  'нажин',
  'назад',
  'назва',
  'наїзд',
  'найда',
  'найми',
  'найра',
  'найти',
  'наказ',
  'накат',
  'накид',
  'накип',
  'накіт',
  'накри',
  'налив',
  'наліт',
  'намаз',
  'намел',
  'намет',
  'намив',
  'намір',
  'намул',
  'намюр',
  'нанду',
  'наниз',
  'нанка',
  'нанос',
  'напад',
  'напис',
  'напів',
  'напій',
  'напір',
  'нараз',
  'нарди',
  'нарив',
  'нарис',
  'наріз',
  'народ',
  'нарта',
  'наруч',
  'наряд',
  'насад',
  'насип',
  'насит',
  'насів',
  'насос',
  'насув',
  'натще',
  'натяг',
  'натяк',
  'наука',
  'нафта',
  'нахил',
  'націл',
  'нація',
  'начіс',
  'начос',
  'наяву',
  'наяда',
  'небіж',
  'невід',
  'неври',
  'невус',
  'негус',
  'недуг',
  'незле',
  'нелад',
  'нелюб',
  'нелюд',
  'немає',
  'неміч',
  'ненин',
  'ненка',
  'нерет',
  'нерка',
  'нерпа',
  'нести',
  'несун',
  'нетля',
  'нетрі',
  'нетто',
  'нецке',
  'нивка',
  'низка',
  'низом',
  'нирка',
  'нитка',
  'ниття',
  'ниций',
  'нівоз',
  'ніжка',
  'ніжно',
  'нікаб',
  'німий',
  'німий',
  'німфа',
  'німча',
  'нірка',
  'нітон',
  'нічий',
  'нічка',
  'нічно',
  'ніяко',
  'нмоль',
  'новак',
  'новий',
  'новик',
  'ножар',
  'ножик',
  'нойон',
  'номад',
  'номен',
  'номен',
  'номер',
  'номос',
  'нонет',
  'норит',
  'норія',
  'норка',
  'норма',
  'норов',
  'носай',
  'носак',
  'носар',
  'носач',
  'носик',
  'носій',
  'носій',
  'носок',
  'нотар',
  'нотка',
  'ночви',
  'ношпа',
  'нощно',
  'нубук',
  'нудко',
  'нудно',
  'нудяр',
  'нужда',
  'нукер',
  'нулик',
  'нумте',
  'нурта',
  'нутро',
  'нюанс',
  'нюхач',
  'нявка',
  'нянин',
  'оазис',
  'обава',
  'обвал',
  'обвід',
  'обгин',
  'обгін',
  'обдув',
  'оберт',
  'обжин',
  'обзив',
  'обком',
  'обліг',
  'облік',
  'обліт',
  'облом',
  'облуд',
  'обман',
  'обмін',
  'обмір',
  'обніж',
  'обора',
  'образ',
  'образ',
  'обрив',
  'обрис',
  'обріз',
  'обріз',
  'обрій',
  'обрік',
  'оброк',
  'обруб',
  'обрус',
  'обруч',
  'обряд',
  'обсув',
  'обсяг',
  'обтік',
  'обхід',
  'обцас',
  'обшир',
  'обшук',
  'овеча',
  'овній',
  'овоїд',
  'огида',
  'оглан',
  'огляд',
  'огрів',
  'огріх',
  'огром',
  'огуда',
  'одежа',
  'одеон',
  'одіти',
  'одчай',
  'ожика',
  'ожина',
  'ожити',
  'озеро',
  'озима',
  'озноб',
  'окапі',
  'окати',
  'океан',
  'окіст',
  'оклад',
  'оклик',
  'окова',
  'окрик',
  'окріп',
  'округ',
  'оксид',
  'оксія',
  'октан',
  'октет',
  'октод',
  'окунь',
  'окути',
  'олеат',
  'олеїн',
  'олень',
  'оленя',
  'олеум',
  'олива',
  'оливо',
  'олімп',
  'оліфа',
  'олово',
  'омана',
  'омбре',
  'омега',
  'омела',
  'омити',
  'омлет',
  'омрак',
  'омуль',
  'онагр',
  'онікс',
  'онова',
  'онука',
  'онуча',
  'онуча',
  'ооліт',
  'опади',
  'опара',
  'опель',
  'опера',
  'опіка',
  'опіум',
  'оплін',
  'опліт',
  'опліч',
  'опока',
  'опора',
  'опсин',
  'оптик',
  'опція',
  'орава',
  'орало',
  'оране',
  'орати',
  'орган',
  'орган',
  'оргія',
  'орден',
  'орден',
  'ордер',
  'ореол',
  'оркан',
  'орлан',
  'орлик',
  'орлій',
  'орлон',
  'орлюк',
  'орляк',
  'орний',
  'оруда',
  'орхіт',
  'орчик',
  'осада',
  'оселя',
  'осика',
  'осище',
  'осище',
  'осінь',
  'оскар',
  'ослик',
  'ослін',
  'осліп',
  'ослюк',
  'осляр',
  'осман',
  'осмат',
  'осмій',
  'осміх',
  'осмос',
  'особа',
  'особа',
  'осоїд',
  'осока',
  'остит',
  'остов',
  'остюк',
  'осуга',
  'осуда',
  'осьде',
  'отава',
  'отако',
  'отако',
  'отара',
  'отвір',
  'отеса',
  'отець',
  'отоді',
  'отоса',
  'отрок',
  'оттак',
  'отуди',
  'отчий',
  'отчич',
  'оупен',
  'офіра',
  'офорт',
  'офсет',
  'офшор',
  'охати',
  'охлос',
  'охляп',
  'охота',
  'охоче',
  'очиці',
  'очища',
  'очкур',
  'очний',
  'павза',
  'павин',
  'павич',
  'павук',
  'павур',
  'пагін',
  'пагри',
  'падіж',
  'паділ',
  'падко',
  'падло',
  'падло',
  'падре',
  'падуб',
  'паейя',
  'пазик',
  'пазур',
  'пайда',
  'пайза',
  'пайка',
  'пайок',
  'пакет',
  'палас',
  'палац',
  'палаш',
  'палій',
  'палка',
  'пампа',
  'панас',
  'панда',
  'панич',
  'панів',
  'панін',
  'панія',
  'панна',
  'панно',
  'панок',
  'папая',
  'папір',
  'папір',
  'парад',
  'параф',
  'парез',
  'парео',
  'парія',
  'парка',
  'парко',
  'парно',
  'парня',
  'парох',
  'парта',
  'парус',
  'парча',
  'парша',
  'пасаж',
  'пасат',
  'пасив',
  'пасія',
  'пасія',
  'паска',
  'пасмо',
  'пасок',
  'паста',
  'пасти',
  'пасти',
  'пасха',
  'патер',
  'патик',
  'патіо',
  'патли',
  'патос',
  'пауза',
  'пафос',
  'пахва',
  'пахта',
  'пацан',
  'пацюк',
  'пачка',
  'пашня',
  'паяти',
  'певне',
  'певно',
  'пегас',
  'пекан',
  'пекар',
  'пекар',
  'пекло',
  'пекти',
  'пелех',
  'пелех',
  'пемза',
  'пенал',
  'пенат',
  'пеніс',
  'пенні',
  'пенні',
  'перга',
  'перед',
  'перія',
  'перко',
  'перло',
  'перон',
  'перса',
  'перст',
  'перти',
  'перун',
  'перше',
  'перше',
  'песик',
  'песто',
  'пестя',
  'петит',
  'петля',
  'печія',
  'пивна',
  'пивце',
  'пижмо',
  'пилка',
  'пилок',
  'пиляк',
  'пиляр',
  'пипка',
  'пиріг',
  'пирій',
  'пирск',
  'писар',
  'писок',
  'питач',
  'питво',
  'питий',
  'питки',
  'питне',
  'пиття',
  'питун',
  'пичка',
  'пищик',
  'пияка',
  'піала',
  'піано',
  'півня',
  'півть',
  'півча',
  'підле',
  'піжон',
  'пізно',
  'пійло',
  'пікап',
  'пікет',
  'пікша',
  'пілат',
  'пілка',
  'пілон',
  'пілот',
  'пінія',
  'пінка',
  'пінта',
  'пінчо',
  'пірат',
  'пірен',
  'пірит',
  'пірол',
  'пірон',
  'піроп',
  'пірце',
  'пісня',
  'пісок',
  'піспа',
  'пітія',
  'пітон',
  'піфія',
  'піфос',
  'піхва',
  'піхта',
  'пічка',
  'пішак',
  'пішак',
  'піший',
  'піший',
  'пішки',
  'піяка',
  'піяка',
  'пласт',
  'плата',
  'плато',
  'плаун',
  'плаха',
  'плебс',
  'плеєр',
  'плеск',
  'плесо',
  'плесь',
  'плече',
  'плись',
  'плита',
  'пліва',
  'плісе',
  'пліть',
  'плоть',
  'площа',
  'плюск',
  'плюсь',
  'пляма',
  'пнище',
  'пнути',
  'побит',
  'побій',
  'побіл',
  'побір',
  'побіч',
  'побут',
  'поваб',
  'повів',
  'повід',
  'повік',
  'повіт',
  'повія',
  'повно',
  'повня',
  'погар',
  'погон',
  'погук',
  'подив',
  'подих',
  'поділ',
  'поділ',
  'подія',
  'подув',
  'поема',
  'позва',
  'позер',
  'позив',
  'позір',
  'позіх',
  'позов',
  'поїзд',
  'поїти',
  'пойда',
  'показ',
  'покер',
  'покій',
  'покіс',
  'покіт',
  'покуп',
  'полба',
  'полив',
  'полик',
  'полин',
  'поліг',
  'полій',
  'полій',
  'поліп',
  'поліс',
  'політ',
  'полог',
  'полоз',
  'полоз',
  'полон',
  'полох',
  'полюс',
  'помах',
  'помел',
  'помиї',
  'помин',
  'помік',
  'помір',
  'помір',
  'поміч',
  'помор',
  'помпа',
  'понос',
  'пончо',
  'понюх',
  'попас',
  'попик',
  'попит',
  'попів',
  'попіл',
  'попок',
  'попса',
  'пораз',
  'порей',
  'порив',
  'поріг',
  'поріз',
  'порно',
  'порок',
  'пором',
  'порох',
  'поруб',
  'порух',
  'поруч',
  'порше',
  'поряд',
  'посаг',
  'посад',
  'посів',
  'посол',
  'посох',
  'посуд',
  'посуш',
  'потай',
  'поташ',
  'потир',
  'потік',
  'потім',
  'потоп',
  'потур',
  'потяг',
  'потяг',
  'похил',
  'похит',
  'похід',
  'почет',
  'почин',
  'почім',
  'пошта',
  'пошук',
  'пошум',
  'поява',
  'право',
  'прайд',
  'праля',
  'прати',
  'праця',
  'праща',
  'преса',
  'пресс',
  'прима',
  'принт',
  'принц',
  'приск',
  'пріль',
  'пріон',
  'пріор',
  'пріти',
  'проба',
  'пробі',
  'проза',
  'промо',
  'просо',
  'проти',
  'профі',
  'проща',
  'прояв',
  'прусс',
  'пряжа',
  'пряля',
  'пряма',
  'пряха',
  'псина',
  'псина',
  'псище',
  'псюга',
  'псюка',
  'псюра',
  'псяка',
  'псяра',
  'птаха',
  'пташа',
  'птиця',
  'птиця',
  'пуант',
  'пугач',
  'пугач',
  'пудик',
  'пудра',
  'пузан',
  'пузир',
  'пузце',
  'пульс',
  'пульт',
  'пункт',
  'пупок',
  'пурга',
  'пурин',
  'пусте',
  'пусто',
  'путря',
  'пуття',
  'пуфик',
  'пухир',
  'пучка',
  'пучок',
  'пушка',
  'пушок',
  'пушок',
  'пушта',
  'пхати',
  'пшоно',
  'рабат',
  'раббі',
  'рабин',
  'равин',
  'радар',
  'раджа',
  'радий',
  'радій',
  'радіо',
  'радон',
  'радця',
  'разер',
  'разок',
  'разом',
  'раїна',
  'райок',
  'район',
  'ракія',
  'ракша',
  'рамка',
  'рампа',
  'рамці',
  'рамця',
  'ранет',
  'ранка',
  'рання',
  'ранок',
  'ранчо',
  'рапід',
  'растр',
  'ратай',
  'ратин',
  'раунд',
  'рафід',
  'рафія',
  'рахва',
  'рахіс',
  'рахіт',
  'раціо',
  'рація',
  'рачки',
  'рачок',
  'раяти',
  'рвати',
  'рдест',
  'ребро',
  'ребус',
  'ревти',
  'ревун',
  'ревун',
  'регбі',
  'реггі',
  'регіт',
  'редан',
  'редис',
  'редут',
  'режим',
  'резит',
  'резол',
  'резон',
  'резус',
  'резус',
  'рейка',
  'рекет',
  'ректи',
  'реліз',
  'реліт',
  'ремез',
  'реміз',
  'ренет',
  'реній',
  'реніт',
  'рента',
  'репер',
  'репет',
  'ретро',
  'ретуш',
  'решка',
  'решта',
  'ржати',
  'рибак',
  'рибка',
  'рибне',
  'рибно',
  'ривок',
  'ридма',
  'рижик',
  'рижій',
  'ризик',
  'рийка',
  'рикша',
  'рикша',
  'римар',
  'ринва',
  'ринда',
  'ринда',
  'риніт',
  'ринка',
  'ринок',
  'рипус',
  'рисак',
  'риска',
  'ритий',
  'ритон',
  'ритор',
  'риття',
  'рифей',
  'рихва',
  'рицар',
  'рицин',
  'рівне',
  'рівно',
  'рівня',
  'рідне',
  'рідні',
  'рідно',
  'рідня',
  'ріжок',
  'різак',
  'різка',
  'різко',
  'різне',
  'різно',
  'різня',
  'різун',
  'рійба',
  'рілля',
  'ріпак',
  'ріпка',
  'ріска',
  'річка',
  'робак',
  'робер',
  'робот',
  'ровер',
  'рогач',
  'рогач',
  'рогіз',
  'родак',
  'родео',
  'родич',
  'родій',
  'рожен',
  'розор',
  'розум',
  'роїти',
  'рокер',
  'рокер',
  'рокош',
  'ролер',
  'ролик',
  'роман',
  'ромей',
  'ромен',
  'ромка',
  'рондо',
  'ронжа',
  'ропак',
  'рости',
  'ростр',
  'ротик',
  'роток',
  'ротор',
  'рочок',
  'рояль',
  'ртуть',
  'рубаї',
  'рубач',
  'рубіж',
  'рубін',
  'рубка',
  'рубль',
  'рудий',
  'рудий',
  'рудня',
  'рудяк',
  'руїна',
  'рукав',
  'рулет',
  'рулон',
  'румак',
  'румба',
  'рунет',
  'рупія',
  'рупор',
  'рурка',
  'русак',
  'русий',
  'русин',
  'русич',
  'русло',
  'рутил',
  'рутин',
  'рутка',
  'руфер',
  'руфія',
  'ручай',
  'ручка',
  'ручно',
  'рушій',
  'рюмса',
  'рюшки',
  'рябий',
  'рябий',
  'рябко',
  'рябоє',
  'рядно',
  'рядок',
  'ряжка',
  'рямця',
  'ряска',
  'рясно',
  'сабза',
  'сабур',
  'саван',
  'савар',
  'сагиб',
  'саджа',
  'садка',
  'садно',
  'садок',
  'саєта',
  'сажка',
  'сажок',
  'сазан',
  'сайга',
  'сайда',
  'сайка',
  'сайка',
  'сайкл',
  'сайра',
  'сакви',
  'сакля',
  'сакма',
  'сакос',
  'салат',
  'салол',
  'салон',
  'салоп',
  'салун',
  'салют',
  'салям',
  'саман',
  'самба',
  'самбо',
  'самбо',
  'самий',
  'саміт',
  'самка',
  'самум',
  'санки',
  'сапер',
  'сапет',
  'сапка',
  'сапун',
  'сарай',
  'саржа',
  'сарич',
  'сарна',
  'сарос',
  'сатин',
  'сатир',
  'сауна',
  'сачок',
  'свара',
  'сваха',
  'светр',
  'свиня',
  'свист',
  'свита',
  'свіжо',
  'свінг',
  'світа',
  'свіча',
  'свояк',
  'свято',
  'сеанс',
  'себін',
  'сегун',
  'седан',
  'сезам',
  'сезон',
  'сексі',
  'секта',
  'селен',
  'селфі',
  'селюк',
  'селюх',
  'семіт',
  'семпл',
  'сенат',
  'сепія',
  'сепія',
  'серга',
  'серен',
  'серин',
  'серія',
  'серна',
  'серум',
  'серце',
  'сесія',
  'сетер',
  'сибас',
  'сивак',
  'сивий',
  'сивий',
  'сивка',
  'сивуч',
  'сигма',
  'сидні',
  'сидун',
  'сизий',
  'сиква',
  'сикоз',
  'силан',
  'силач',
  'силос',
  'силур',
  'сильф',
  'синап',
  'синаш',
  'сингл',
  'сингл',
  'синів',
  'синій',
  'синод',
  'синок',
  'синті',
  'синто',
  'синус',
  'синьо',
  'синяк',
  'сипай',
  'сипко',
  'сипло',
  'сирий',
  'сирин',
  'сирок',
  'сирок',
  'сироп',
  'сисак',
  'сисун',
  'ситал',
  'ситий',
  'ситий',
  'ситко',
  'ситно',
  'ситро',
  'ситце',
  'сифон',
  'сичуг',
  'сичуг',
  'сівач',
  'сівба',
  'сідач',
  'сідло',
  'сієна',
  'сієра',
  'сікач',
  'сікач',
  'сікти',
  'сімка',
  'сінаж',
  'сінце',
  'сінці',
  'сірий',
  'сірий',
  'сірка',
  'сірко',
  'сіряк',
  'сіряк',
  'сісти',
  'сітка',
  'січка',
  'січна',
  'сішка',
  'сіяти',
  'сіяти',
  'скаву',
  'скайп',
  'скала',
  'скарб',
  'скарн',
  'скаут',
  'сквер',
  'сквот',
  'сквош',
  'скейт',
  'скеля',
  'скетч',
  'скиба',
  'скіпа',
  'скіфл',
  'скіць',
  'склад',
  'склеп',
  'скляр',
  'скоба',
  'скопа',
  'скоро',
  'скорс',
  'скоса',
  'скотч',
  'скраб',
  'скрап',
  'скраю',
  'скрик',
  'скрип',
  'скрут',
  'скугу',
  'скунс',
  'слабо',
  'слава',
  'слайд',
  'слати',
  'слати',
  'сленг',
  'слива',
  'слина',
  'слінг',
  'сліпи',
  'слово',
  'слоїк',
  'слоня',
  'слуга',
  'слюда',
  'смага',
  'смайл',
  'смерд',
  'смерк',
  'смерч',
  'смисл',
  'сміти',
  'смола',
  'сморж',
  'смуга',
  'смузі',
  'смута',
  'снага',
  'снити',
  'сниця',
  'снище',
  'сноза',
  'собор',
  'совка',
  'совок',
  'содом',
  'сойка',
  'сокет',
  'сокіл',
  'сокіл',
  'солея',
  'солід',
  'солод',
  'соляр',
  'соляр',
  'сомик',
  'сомон',
  'сонар',
  'сонет',
  'сонно',
  'сонце',
  'сонях',
  'сопка',
  'сопло',
  'сопля',
  'сопти',
  'сопун',
  'сорго',
  'сорит',
  'сором',
  'сорус',
  'соска',
  'сосна',
  'сосок',
  'сотий',
  'сотка',
  'сотня',
  'софіт',
  'софка',
  'сошка',
  'спазм',
  'спати',
  'спека',
  'спекл',
  'сперш',
  'спина',
  'спирт',
  'спити',
  'спиця',
  'сплав',
  'сплив',
  'сплін',
  'сплюх',
  'спонж',
  'спора',
  'споро',
  'спорт',
  'спред',
  'спрей',
  'сприт',
  'спрут',
  'спурт',
  'спуск',
  'спуск',
  'спуст',
  'спуст',
  'ссати',
  'стадо',
  'стаєр',
  'стаза',
  'стала',
  'стале',
  'стало',
  'сталь',
  'станс',
  'старе',
  'старт',
  'стати',
  'стать',
  'ствол',
  'створ',
  'стезя',
  'стейк',
  'стела',
  'стеля',
  'стенд',
  'стент',
  'стило',
  'стиль',
  'стиль',
  'стиск',
  'стиха',
  'стіна',
  'стовп',
  'стоїк',
  'стокс',
  'стома',
  'стопа',
  'стопа',
  'сторч',
  'стояк',
  'стоян',
  'страж',
  'страз',
  'страх',
  'стрес',
  'стриб',
  'стриж',
  'стрий',
  'стрим',
  'стрих',
  'стрій',
  'стріт',
  'стріч',
  'строк',
  'строп',
  'струг',
  'струг',
  'струм',
  'струп',
  'струс',
  'стуга',
  'стуга',
  'стума',
  'ступа',
  'стяти',
  'субак',
  'субіч',
  'сувій',
  'сугак',
  'судак',
  'судан',
  'суддя',
  'судза',
  'судно',
  'судок',
  'суєта',
  'сукно',
  'сукня',
  'сулія',
  'суміш',
  'сумка',
  'сумно',
  'суніт',
  'супер',
  'супін',
  'сурик',
  'сурма',
  'сусак',
  'сусід',
  'сусло',
  'сутаж',
  'сутри',
  'суфій',
  'суфле',
  'сухар',
  'сухий',
  'сучий',
  'сучка',
  'сучок',
  'суччя',
  'сушка',
  'сушня',
  'сущий',
  'сфера',
  'схема',
  'схима',
  'схлип',
  'сходи',
  'схоже',
  'схрон',
  'сцена',
  'сцяти',
  'сьєра',
  'сьорб',
  'сюдою',
  'сюжет',
  'сюїта',
  'сяєво',
  'сяжки',
  'сяйво',
  'сяйно',
  'сякий',
  'сяяти',
  'табес',
  'табір',
  'табло',
  'табор',
  'табун',
  'тавот',
  'тавро',
  'таган',
  'тазик',
  'таїна',
  'таїти',
  'тайга',
  'таймз',
  'таймс',
  'тайна',
  'такий',
  'такир',
  'такса',
  'такса',
  'таксі',
  'талан',
  'талап',
  'талер',
  'талес',
  'талий',
  'таліб',
  'талій',
  'талія',
  'талом',
  'талон',
  'тальк',
  'таляр',
  'тамга',
  'тамка',
  'тамки',
  'танго',
  'танід',
  'танін',
  'танок',
  'тапас',
  'тапер',
  'тапір',
  'таран',
  'тарах',
  'тариф',
  'татів',
  'татко',
  'тафта',
  'тахля',
  'тахта',
  'тачка',
  'твань',
  'тверк',
  'твіст',
  'театр',
  'тезис',
  'тезка',
  'тезко',
  'теїзм',
  'теїст',
  'текст',
  'текти',
  'телеп',
  'теліп',
  'телур',
  'тембр',
  'темка',
  'темна',
  'темно',
  'тенге',
  'теніс',
  'теніт',
  'тенор',
  'тенор',
  'тепер',
  'тепло',
  'тепло',
  'терем',
  'терен',
  'терен',
  'тереш',
  'терка',
  'терло',
  'терни',
  'терня',
  'терор',
  'терти',
  'тертя',
  'тесак',
  'тесла',
  'тесло',
  'тесля',
  'тесть',
  'тетрі',
  'теург',
  'техно',
  'течія',
  'течка',
  'тещин',
  'тивун',
  'тигря',
  'тикер',
  'тилій',
  'тимін',
  'тимол',
  'тимус',
  'тиння',
  'тинок',
  'тиньк',
  'типаж',
  'тираж',
  'тиран',
  'тирит',
  'тирка',
  'тирло',
  'тирса',
  'титан',
  'титан',
  'титан',
  'титар',
  'титла',
  'титло',
  'титул',
  'тихий',
  'тичба',
  'тичка',
  'тичок',
  'тишко',
  'тіара',
  'тіпун',
  'тісто',
  'тітка',
  'тічка',
  'тічня',
  'ткаля',
  'ткати',
  'тлінь',
  'тліти',
  'тнути',
  'товар',
  'товба',
  'товща',
  'тодес',
  'тойон',
  'токай',
  'токар',
  'токен',
  'толар',
  'томат',
  'томат',
  'томик',
  'томос',
  'тондо',
  'тонер',
  'тонік',
  'тонна',
  'тонус',
  'топаз',
  'топік',
  'топіт',
  'топка',
  'топос',
  'топуз',
  'торба',
  'торит',
  'торій',
  'торік',
  'торон',
  'торос',
  'торох',
  'тотем',
  'точка',
  'точно',
  'точок',
  'трава',
  'тракт',
  'транс',
  'транш',
  'траса',
  'траст',
  'трата',
  'траур',
  'треба',
  'треба',
  'трейд',
  'трейл',
  'трель',
  'тренд',
  'тренч',
  'трест',
  'трефа',
  'триба',
  'тризм',
  'трико',
  'трина',
  'трипс',
  'триух',
  'тричі',
  'тріас',
  'трієр',
  'тріод',
  'тріск',
  'трісь',
  'троль',
  'тромб',
  'тромп',
  'тропа',
  'трохи',
  'троща',
  'трояк',
  'троян',
  'труба',
  'труна',
  'трупа',
  'труси',
  'трюмо',
  'тсуга',
  'туаль',
  'тубик',
  'тубус',
  'тугий',
  'тудою',
  'тужно',
  'тукан',
  'тулій',
  'тулія',
  'тулуб',
  'тумак',
  'тумак',
  'туман',
  'туман',
  'тумба',
  'тумен',
  'тумор',
  'тупак',
  'тупак',
  'тупий',
  'тупик',
  'тупіт',
  'турач',
  'турів',
  'турка',
  'турне',
  'турун',
  'турча',
  'тусан',
  'тутво',
  'тутка',
  'тутки',
  'туфіт',
  'туфля',
  'туфта',
  'тушка',
  'тушка',
  'тьотя',
  'тюбаж',
  'тюбик',
  'тюнер',
  'тюпки',
  'тюрбе',
  'тюрбо',
  'тюрбо',
  'тюрма',
  'тютюн',
  'тягар',
  'тягач',
  'тягло',
  'тягти',
  'тягун',
  'тяжба',
  'тяжко',
  'тяжок',
  'тямка',
  'уазик',
  'убити',
  'убрід',
  'убрус',
  'убути',
  'увага',
  'увеїт',
  'увера',
  'уверх',
  'увись',
  'увити',
  'уволю',
  'угара',
  'углиб',
  'углич',
  'угода',
  'угорі',
  'угору',
  'угрин',
  'удати',
  'удача',
  'удень',
  'удова',
  'удома',
  'ужити',
  'узбіч',
  'узвар',
  'узвіз',
  'узути',
  'узяти',
  'уїсти',
  'уйгур',
  'уймак',
  'уклад',
  'уклін',
  'уклон',
  'украй',
  'укріп',
  'укупі',
  'улити',
  'уліво',
  'ульва',
  'улюлю',
  'умбон',
  'умбра',
  'умент',
  'умити',
  'умить',
  'уміст',
  'уміти',
  'умова',
  'унабі',
  'унада',
  'унизу',
  'уніат',
  'унізм',
  'уночі',
  'унтер',
  'унуча',
  'унція',
  'унчик',
  'упень',
  'уперш',
  'упити',
  'упіст',
  'уплав',
  'уплив',
  'упору',
  'упряж',
  'ураза',
  'уреат',
  'урема',
  'урина',
  'урити',
  'урода',
  'услід',
  'усмак',
  'усміх',
  'усний',
  'усоте',
  'успід',
  'успіх',
  'устав',
  'усташ',
  'уступ',
  'устяж',
  'усуху',
  'усюди',
  'усяко',
  'утвір',
  'утеча',
  'утиль',
  'утиск',
  'утіха',
  'утлий',
  'утома',
  'утяти',
  'ухати',
  'учвал',
  'учень',
  'учити',
  'учора',
  'учути',
  'ушити',
  'ушкал',
  'ущент',
  'уявки',
  'уявне',
  'уявно',
  'фабра',
  'фавор',
  'фагот',
  'фазан',
  'фазис',
  'файда',
  'файка',
  'файно',
  'факел',
  'факір',
  'факто',
  'фалда',
  'фалос',
  'фальц',
  'фальш',
  'фальш',
  'фанат',
  'фанза',
  'фарад',
  'фарба',
  'фарма',
  'фасад',
  'фасет',
  'фаска',
  'фасон',
  'фатва',
  'фатин',
  'фатум',
  'фауна',
  'фацет',
  'фація',
  'фаянс',
  'феаки',
  'фелах',
  'фелон',
  'феніл',
  'феніт',
  'фенол',
  'ферзь',
  'ферит',
  'ферма',
  'ферум',
  'феска',
  'фетва',
  'фетиш',
  'фіакр',
  'фібра',
  'фіглі',
  'фігня',
  'фіґлі',
  'фіґня',
  'фідер',
  'фізик',
  'фізія',
  'фікус',
  'фікшн',
  'філей',
  'філер',
  'філер',
  'філіт',
  'філія',
  'фільм',
  'фільц',
  'фімоз',
  'фінал',
  'фінік',
  'фініш',
  'фінка',
  'фіорд',
  'фірма',
  'фітин',
  'фітол',
  'фішка',
  'фйорд',
  'флаєр',
  'фланг',
  'фланк',
  'флейц',
  'флінт',
  'флірт',
  'флокс',
  'флопі',
  'флора',
  'флуор',
  'флюїд',
  'флюор',
  'фляга',
  'фляки',
  'фобія',
  'фокус',
  'фолат',
  'фоліо',
  'фолія',
  'фольк',
  'фомоз',
  'фондю',
  'фонон',
  'форма',
  'форте',
  'форум',
  'фотка',
  'фотон',
  'фраєр',
  'фраза',
  'франк',
  'франт',
  'фрапе',
  'фрахт',
  'фреза',
  'фрейм',
  'френч',
  'фреон',
  'фрити',
  'фронт',
  'фрукт',
  'фугас',
  'фуете',
  'фужер',
  'фузія',
  'фукус',
  'фуляр',
  'фураж',
  'фуран',
  'фурія',
  'фурка',
  'фурма',
  'фурор',
  'фуррр',
  'футро',
  'фюзен',
  'фюрер',
  'хавав',
  'хавка',
  'хаджі',
  'хадис',
  'хадіс',
  'хазар',
  'хайку',
  'хайль',
  'хакан',
  'хакас',
  'хакер',
  'халат',
  'халва',
  'халіф',
  'хамам',
  'хамів',
  'хамка',
  'хамло',
  'хамло',
  'хамон',
  'хамса',
  'ханат',
  'ханів',
  'ханум',
  'ханша',
  'хапко',
  'хапун',
  'харам',
  'харза',
  'харчо',
  'хасид',
  'хаскі',
  'хатка',
  'хатнє',
  'хафіз',
  'хвала',
  'хвать',
  'хвиля',
  'хвись',
  'хвіст',
  'хвіть',
  'хвось',
  'хедер',
  'хедер',
  'хелат',
  'хелло',
  'хемоз',
  'херес',
  'хибко',
  'хижак',
  'хижий',
  'хижка',
  'хизун',
  'хирий',
  'хирно',
  'хиряк',
  'хитро',
  'хитун',
  'хіазм',
  'хідлі',
  'хідня',
  'хілер',
  'хілус',
  'хімік',
  'хімія',
  'хімус',
  'хінін',
  'хінон',
  'хіпан',
  'хіпар',
  'хітин',
  'хітон',
  'хлист',
  'хлист',
  'хлись',
  'хлюст',
  'хлюща',
  'хляки',
  'хлясь',
  'хляти',
  'хмара',
  'хміль',
  'хмуро',
  'хоана',
  'хобіт',
  'хобот',
  'ходак',
  'ходжа',
  'ходка',
  'ходок',
  'ходок',
  'ходун',
  'хокей',
  'холін',
  'холка',
  'холод',
  'холоп',
  'холуй',
  'хомут',
  'хопер',
  'хопер',
  'хопта',
  'хорал',
  'хорда',
  'хорей',
  'хорея',
  'хором',
  'хосен',
  'хохол',
  'хохол',
  'хрест',
  'хрунь',
  'хруск',
  'хруст',
  'хрусь',
  'хрьоп',
  'хрюша',
  'хряск',
  'хрясь',
  'худий',
  'худяк',
  'хумус',
  'хунта',
  'хурал',
  'хурма',
  'хутір',
  'хутко',
  'хутро',
  'хуцпа',
  'цадик',
  'цанга',
  'цапик',
  'цапів',
  'цапок',
  'цапфа',
  'царат',
  'царик',
  'царів',
  'царок',
  'царок',
  'цвіль',
  'цвіть',
  'цвьох',
  'цебер',
  'цебро',
  'цегла',
  'цедра',
  'цезар',
  'цезій',
  'целон',
  'ценоз',
  'центр',
  'центр',
  'церит',
  'церій',
  'цесар',
  'цесія',
  'цехін',
  'цибук',
  'цибух',
  'циган',
  'цигун',
  'цикля',
  'цимес',
  'цимус',
  'цинга',
  'цинік',
  'цинія',
  'цироз',
  'циста',
  'цитоз',
  'цитра',
  'цифра',
  'цівка',
  'цілий',
  'цілик',
  'цілко',
  'цінно',
  'ціпак',
  'ціпка',
  'ціпок',
  'цісар',
  'цнота',
  'цокіт',
  'цугом',
  'цукат',
  'цукор',
  'цурка',
  'цуцик',
  'цьвох',
  'цьора',
  'цятка',
  'чабак',
  'чабан',
  'чабер',
  'чаван',
  'чавун',
  'чавун',
  'чавуш',
  'чагар',
  'чадно',
  'чадра',
  'чаєня',
  'чаїти',
  'чайка',
  'чайка',
  'чайна',
  'чайок',
  'чакан',
  'чакра',
  'чалап',
  'чалий',
  'чалий',
  'чалма',
  'чамка',
  'чанго',
  'чапан',
  'чапля',
  'чарка',
  'часен',
  'часок',
  'часом',
  'часто',
  'чашка',
  'чвань',
  'чвара',
  'чвирк',
  'чвиря',
  'чедер',
  'чекан',
  'чекан',
  'чемно',
  'черва',
  'черви',
  'черга',
  'черед',
  'черен',
  'череп',
  'черес',
  'чернь',
  'чернь',
  'честь',
  'чешка',
  'чигир',
  'чижик',
  'чийсь',
  'чилим',
  'чинар',
  'чинно',
  'чипси',
  'чирва',
  'чирка',
  'чирка',
  'чирок',
  'чирок',
  'чиряк',
  'число',
  'чисто',
  'читач',
  'читво',
  'чифір',
  'чихир',
  'чіпка',
  'чіпок',
  'чітко',
  'чічка',
  'чобіт',
  'човен',
  'чопик',
  'чопок',
  'чорні',
  'чорно',
  'чортя',
  'чотар',
  'чотки',
  'чохол',
  'чубар',
  'чубик',
  'чубок',
  'чубук',
  'чувак',
  'чувал',
  'чуваш',
  'чудар',
  'чудно',
  'чужак',
  'чужий',
  'чужий',
  'чукча',
  'чулий',
  'чумак',
  'чутий',
  'чутка',
  'чутко',
  'чутно',
  'чуття',
  'чучхе',
  'чушка',
  'чушка',
  'чхати',
  'шабат',
  'шабаш',
  'шабер',
  'шабля',
  'шавка',
  'шайба',
  'шакал',
  'шалаш',
  'шалик',
  'шалон',
  'шалот',
  'шаман',
  'шамот',
  'шанкр',
  'шапка',
  'шасла',
  'шасть',
  'шатен',
  'шатія',
  'шатро',
  'шаттл',
  'шатун',
  'шатуш',
  'шафар',
  'шафка',
  'шахва',
  'шахід',
  'шахта',
  'шашка',
  'шваль',
  'шваля',
  'шварк',
  'швець',
  'швирг',
  'швора',
  'шевро',
  'шелак',
  'шелеп',
  'шелех',
  'шелті',
  'шельф',
  'шелюг',
  'шелюг',
  'шеляг',
  'шемая',
  'шепіт',
  'шерег',
  'шерех',
  'шериф',
  'шерше',
  'шефів',
  'шибер',
  'шибка',
  'шиїзм',
  'шийка',
  'шинка',
  'шиння',
  'шинок',
  'шипик',
  'шипун',
  'ширма',
  'шитво',
  'шитий',
  'шиття',
  'шифер',
  'шифон',
  'шихта',
  'шишак',
  'шишка',
  'шіацу',
  'шкала',
  'шкапа',
  'шквал',
  'шкіра',
  'шкода',
  'шкода',
  'шкода',
  'школа',
  'шкура',
  'шланг',
  'шлейф',
  'шльоп',
  'шлюха',
  'шмата',
  'шмига',
  'шморг',
  'шнапс',
  'шокер',
  'шолом',
  'шопка',
  'шорея',
  'шорка',
  'шорка',
  'шорти',
  'шофер',
  'шпага',
  'шпала',
  'шпана',
  'шпара',
  'шпача',
  'шпень',
  'шпиль',
  'шпиця',
  'шпона',
  'шпора',
  'шприц',
  'шпрот',
  'шпуля',
  'шпунт',
  'шримс',
  'шрифт',
  'шруті',
  'штаба',
  'штазі',
  'штамб',
  'штамп',
  'штани',
  'штейн',
  'штетл',
  'штиба',
  'штиль',
  'штиль',
  'штирк',
  'штифт',
  'штовх',
  'штора',
  'шторм',
  'штраф',
  'штрек',
  'штрик',
  'штрик',
  'штрих',
  'штука',
  'штурм',
  'штурх',
  'шубка',
  'шувар',
  'шугай',
  'шугай',
  'шудра',
  'шукач',
  'шулер',
  'шулий',
  'шулик',
  'шуляк',
  'шумко',
  'шумно',
  'шумок',
  'шурин',
  'шурпа',
  'шуруп',
  'шуряк',
  'шусть',
  'шутий',
  'шуфля',
  'шушун',
  'шхери',
  'шхуна',
  'щасні',
  'щасно',
  'щастя',
  'щебет',
  'щебіт',
  'щедро',
  'щемно',
  'щерба',
  'щигля',
  'щипак',
  'щипок',
  'щипці',
  'щирий',
  'щиток',
  'щіпка',
  'щітка',
  'щічка',
  'щогла',
  'щодня',
  'щодуж',
  'щоніч',
  'щопта',
  'щораз',
  'щорік',
  'щоріч',
  'щупак',
  'щупля',
  'щурик',
  'щурка',
  'щучий',
  'щучин',
  'щучка',
  'югурт',
  'юдоль',
  'юїтка',
  'юкола',
  'юніор',
  'юнкер',
  'юнкор',
  'юннат',
  'юрист',
  'юрода',
  'юрфак',
  'юферс',
  'юшити',
  'ябеда',
  'явити',
  'явище',
  'явний',
  'яволь',
  'ягель',
  'ягода',
  'ягуар',
  'ядуха',
  'ядуче',
  'яєчко',
  'яєчня',
  'яєшня',
  'якось',
  'ялець',
  'ялина',
  'ялиця',
  'ялоза',
  'ямаха',
  'ямища',
  'ямище',
  'ямний',
  'ямник',
  'янгол',
  'янтар',
  'ярига',
  'яриза',
  'ярина',
  'яриця',
  'яріти',
  'яркий',
  'ярлик',
  'ярові',
  'яруга',
  'ярчук',
  'ясень',
  'ясмин',
  'ясний',
  'яспис',
  'яхонт',
  'ячати',
  'ячний',
]
