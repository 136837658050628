export const WORDS = [
  'чайка',
  'пучок',
  'шапка',
  'загар',
  'туфля',
  'епоха',
  'сироп',
  'гамма',
  'вишка',
  'хутро',
  'хурма',
  'клоун',
  'янгол',
  'потоп',
  'бичок',
  'васал',
  'шрифт',
  'вивих',
  'тьотя',
  'обруч',
  'ферит',
  'кварц',
  'шибка',
  'казна',
  'чашка',
  'балон',
  'цукор',
  'плита',
  'жакет',
  'запит',
  'вузол',
  'капот',
  'слово',
  'юрист',
  'муляж',
  'обвал',
  'вушко',
  'декан',
  'редис',
  'шериф',
  'ложка',
  'мулат',
  'вдова',
  'аорта',
  'тезис',
  'лазер',
  'опора',
  'комод',
  'балет',
  'короб',
  'музей',
  'дукат',
  'черга',
  'жорно',
  'вовна',
  'тачка',
  'чапля',
  'штраф',
  'порох',
  'свист',
  'дотик',
  'каюта',
  'жираф',
  'допит',
  'тавро',
  'грива',
  'адепт',
  'колос',
  'бекон',
  'мороз',
  'стриж',
  'кажан',
  'намул',
  'мужик',
  'цегла',
  'позер',
  'канон',
  'казан',
  'пупок',
  'палац',
  'груба',
  'центр',
  'гусар',
  'шакал',
  'кирка',
  'кожух',
  'батон',
  'павук',
  'смола',
  'подих',
  'башта',
  'халва',
  'лаваш',
  'паска',
  'салон',
  'баняк',
  'волхв',
  'робот',
  'янтар',
  'шпора',
  'щастя',
  'тренд',
  'шторм',
  'турка',
  'орган',
  'шашка',
  'сцена',
  'мушля',
  'блоха',
  'пуфик',
  'стейк',
  'келих',
  'фасад',
  'спуск',
  'кофта',
  'лоток',
  'шишка',
  'пудра',
  'гичка',
  'атлас',
  'ризик',
  'лапки',
  'гайок',
  'марля',
  'акциз',
  'гачок',
  'догма',
  'палка',
  'дебют',
  'павич',
  'число',
  'дзьоб',
  'купка',
  'жриця',
  'битва',
  'пайок',
  'мозок',
  'номер',
  'обман',
  'шайба',
  'пляма',
  'кухар',
  'бабця',
  'лимон',
  'дивак',
  'тембр',
  'обряд',
  'грижа',
  'папая',
  'сором',
  'калач',
  'слина',
  'катод',
  'тайга',
  'скоба',
  'дуель',
  'карат',
  'халат',
  'бубка',
  'кегль',
  'явище',
  'губка',
  'синус',
  'атака',
  'етнос',
  'наука',
  'аркуш',
  'косяк',
  'шофер',
  'спина',
  'тариф',
  'завод',
  'балка',
  'бобер',
  'чохол',
  'фрукт',
  'жерло',
  'лицар',
  'квест',
  'сачок',
  'втеча',
  'потяг',
  'носик',
  'ялиця',
  'бонус',
  'запас',
  'орден',
  'схрон',
  'амеба',
  'отара',
  'втома',
  'лампа',
  'плата',
  'велюр',
  'фрейм',
  'зазор',
  'рядок',
  'козел',
  'альфа',
  'мряка',
  'колба',
  'плоть',
  'пекар',
  'троль',
  'парус',
  'вигин',
  'садок',
  'склеп',
  'глузд',
  'школа',
  'преса',
  'вираз',
  'арешт',
  'рулон',
  'пугач',
  'голка',
  'пошта',
  'занос',
  'князь',
  'зошит',
  'дужка',
  'качка',
  'мойва',
  'гейша',
  'литка',
  'каска',
  'накип',
  'лемур',
  'бухта',
  'кокон',
  'акорд',
  'фауна',
  'вплив',
  'вазон',
  'сяйво',
  'мийка',
  'накат',
  'лежак',
  'хакер',
  'шахта',
  'сюжет',
  'вапно',
  'жучок',
  'пушок',
  'мушка',
  'графа',
  'молот',
  'жокей',
  'гладь',
  'анкер',
  'кубик',
  'кагор',
  'лотос',
  'дошка',
  'запис',
  'бачок',
  'скарб',
  'рицар',
  'ривок',
  'дятел',
  'гуска',
  'ребро',
  'гольф',
  'запах',
  'стиль',
  'буйок',
  'баран',
  'жменя',
  'тирса',
  'цуцик',
  'олень',
  'жетон',
  'намет',
  'народ',
  'крупа',
  'форум',
  'банка',
  'шкала',
  'мотор',
  'актив',
  'дикун',
  'вклад',
  'океан',
  'стопа',
  'глава',
  'гусак',
  'нарди',
  'титан',
  'фляга',
  'мавка',
  'свояк',
  'роман',
  'пасмо',
  'тягар',
  'вуйко',
  'битка',
  'змова',
  'сушка',
  'каста',
  'афера',
  'факел',
  'мурал',
  'табун',
  'бануш',
  'муфта',
  'самба',
  'решта',
  'торба',
  'ласун',
  'кузов',
  'голос',
  'житло',
  'свято',
  'зрада',
  'муляр',
  'кадет',
  'тенор',
  'бочка',
  'жатка',
  'совок',
  'жнива',
  'токар',
  'замах',
  'увага',
  'томат',
  'кепка',
  'сонях',
  'батут',
  'затор',
  'полюс',
  'шпиль',
  'чужак',
  'буфет',
  'збори',
  'квота',
  'рейка',
  'сукня',
  'озеро',
  'топаз',
  'аудит',
  'спазм',
  'розум',
  'страх',
  'койот',
  'гниль',
  'шлейф',
  'норка',
  'хижак',
  'карта',
  'пульт',
  'секта',
  'сеанс',
  'голуб',
  'театр',
  'ворон',
  'носок',
  'синяк',
  'качур',
  'назва',
  'дрейф',
  'циган',
  'ролик',
  'склад',
  'показ',
  'посох',
  'шатен',
  'йодль',
  'шолом',
  'тумба',
  'демон',
  'байда',
  'акула',
  'викуп',
  'бузок',
  'оберт',
  'штурм',
  'аванс',
  'азарт',
  'пауза',
  'гамак',
  'радар',
  'тесак',
  'вольт',
  'тютюн',
  'малюк',
  'актор',
  'жолоб',
  'дрова',
  'порок',
  'фокус',
  'конус',
  'емаль',
  'лютий',
  'листя',
  'оленя',
  'фраза',
  'одежа',
  'банда',
  'оскар',
  'зграя',
  'стадо',
  'нахил',
  'жилет',
  'димка',
  'драма',
  'вожак',
  'замша',
  'канат',
  'кювет',
  'штиль',
  'вдача',
  'буряк',
  'маляр',
  'видра',
  'каток',
  'алича',
  'траса',
  'плече',
  'обрив',
  'покер',
  'казус',
  'ангел',
  'сонце',
  'праця',
  'балик',
  'лиман',
  'зебра',
  'валик',
  'пафос',
  'чужий',
  'вигук',
  'клема',
  'режим',
  'багно',
  'кубок',
  'масив',
  'цифра',
  'собор',
  'умова',
  'пекло',
  'весло',
  'хамса',
  'парка',
  'добро',
  'гумор',
  'краса',
  'шинка',
  'ножик',
  'ослик',
  'сойка',
  'бетон',
  'пасха',
  'глина',
  'скеля',
  'сваха',
  'возик',
  'насип',
  'горло',
  'хобот',
  'самка',
  'кубло',
  'мочка',
  'схема',
  'життя',
  'касир',
  'труба',
  'тираж',
  'драйв',
  'штрих',
  'заява',
  'нюанс',
  'хвиля',
  'сопло',
  'масаж',
  'спирт',
  'помпа',
  'опади',
  'кисть',
  'кулак',
  'курка',
  'барак',
  'спрей',
  'раунд',
  'такса',
  'сумка',
  'рулет',
  'комар',
  'товар',
  'бренд',
  'сауна',
  'прайд',
  'завал',
  'штамп',
  'ялина',
  'висип',
  'гопак',
  'рибак',
  'насос',
  'заряд',
  'надра',
  'фугас',
  'навар',
  'санки',
  'дочка',
  'холод',
  'майор',
  'байка',
  'коала',
  'миска',
  'банан',
  'удача',
  'шкода',
  'сходи',
  'шуруп',
  'вудка',
  'буква',
  'шабля',
  'туман',
  'сквер',
  'шприц',
  'абзац',
  'лопух',
  'зброя',
  'книга',
  'риска',
  'флора',
  'ширма',
  'дубок',
  'видих',
  'човен',
  'метод',
  'гуляш',
  'осика',
  'чутка',
  'таран',
  'кулон',
  'берег',
  'бланк',
  'тубус',
  'погон',
  'тонус',
  'аврал',
  'чуття',
  'сухар',
  'барон',
  'лазня',
  'текст',
  'скунс',
  'стать',
  'корба',
  'хвала',
  'врода',
  'голод',
  'латук',
  'булка',
  'хрест',
  'домен',
  'кобра',
  'кущик',
  'соска',
  'корма',
  'серце',
  'доказ',
  'дилер',
  'тонна',
  'чумак',
  'сифон',
  'пункт',
  'будка',
  'ранок',
  'княжа',
  'земля',
  'хмара',
  'котик',
  'жнець',
  'фронт',
  'кумис',
  'просо',
  'тромб',
  'гудок',
  'галас',
  'натяк',
  'буття',
  'газон',
  'район',
  'особа',
  'корал',
  'банер',
  'рояль',
  'ягода',
  'пилок',
  'геном',
  'бейдж',
  'модем',
  'пачка',
  'хомут',
  'сосна',
  'хокей',
  'онука',
  'судно',
  'вокал',
  'напис',
  'латка',
  'варан',
  'бокал',
  'спора',
  'струм',
  'стеля',
  'светр',
  'кегля',
  'груди',
  'вождь',
  'конюх',
  'багаж',
  'лапка',
  'форма',
  'фазан',
  'весна',
  'гвинт',
  'фарба',
  'шпала',
  'ампер',
  'глива',
  'стенд',
  'танок',
  'кодер',
  'сирок',
  'гроно',
  'тотем',
  'бляха',
  'фанат',
  'макет',
  'дартс',
  'рукав',
  'карма',
  'гарем',
  'кураж',
  'катер',
  'кумир',
  'ватра',
  'стрес',
  'купол',
  'птиця',
  'перон',
  'магма',
  'вуаль',
  'нафта',
  'любов',
  'масло',
  'нирка',
  'джгут',
  'тазик',
  'влада',
  'казка',
  'герой',
  'моток',
  'штора',
  'валет',
  'донор',
  'моряк',
  'долар',
  'сурма',
  'крига',
  'блиск',
  'бомба',
  'сапка',
  'димар',
  'дощик',
  'берет',
  'алмаз',
  'хамон',
  'автор',
  'наказ',
  'багач',
  'пухир',
  'хопта',
  'екран',
  'будяк',
  'смерч',
  'атлет',
  'шпрот',
  'трава',
  'пульс',
  'мопед',
  'титул',
  'букет',
  'курча',
  'окунь',
  'бутон',
  'салют',
  'жабка',
  'чакра',
  'культ',
  'рупор',
  'кокос',
  'бабай',
  'фреза',
  'мавпа',
  'ферма',
  'багет',
  'горня',
  'спека',
  'сазан',
  'кабан',
  'монах',
  'фурор',
  'пемза',
  'колаж',
  'слива',
  'огляд',
  'веган',
  'сапер',
  'кухня',
  'сарай',
  'посуд',
  'узвар',
  'паста',
  'груша',
  'траур',
  'пшоно',
  'декор',
  'шпага',
  'солод',
  'угода',
  'капля',
  'мошка',
  'буфер',
  'лунка',
  'смуга',
  'закон',
  'дерун',
  'штани',
  'диван',
  'ручка',
  'нитка',
  'зерно',
  'допис',
  'коник',
  'гроза',
  'гумка',
  'котел',
  'сплав',
  'шаман',
  'пазур',
  'проба',
  'нагар',
  'вулик',
  'верба',
  'парад',
  'брухт',
  'баржа',
  'гирло',
  'суддя',
  'оселя',
  'козак',
  'фотон',
  'дамба',
  'кучер',
  'салат',
  'криза',
  'витяг',
  'сучок',
  'панда',
  'батат',
  'ангар',
  'пором',
  'площа',
  'ожина',
  'крило',
  'город',
  'базар',
  'кавун',
  'ванна',
  'куток',
  'агент',
  'чарка',
  'сусло',
  'акрил',
  'вихор',
  'вуста',
  'офшор',
  'кошик',
  'курок',
  'вишня',
  'спорт',
  'скотч',
  'вилка',
  'козир',
  'палас',
  'ефект',
  'майно',
  'маска',
  'образ',
  'мотив',
  'гараж',
  'цедра',
  'учень',
  'вагон',
  'песик',
  'вавка',
  'брама',
  'рамка',
  'випас',
  'кобза',
  'хатка',
  'сфера',
  'канал',
  'замок',
  'цукат',
  'вугор',
  'броня',
  'петля',
  'свиня',
  'анонс',
  'поема',
  'ореол',
  'димок',
  'думка',
  'качан',
  'жереб',
  'чавун',
  'амбар',
  'кермо',
  'рампа',
  'ребус',
  'сонар',
  'манеж',
  'ринок',
  'пенал',
  'майка',
  'сонет',
  'омлет',
  'ордер',
  'пакет',
  'ртуть',
  'шифер',
  'шинок',
  'марка',
  'полин',
  'олива',
  'дупло',
  'брова',
  'група',
  'кабак',
  'лавка',
  'вирок',
  'пасок',
  'читач',
  'кольт',
  'килим',
  'норма',
  'бабка',
  'дотеп',
  'тепло',
  'дубль',
  'мазок',
  'сезон',
  'етика',
  'відро',
  'ґанок',
  'дідух',
  'регбі',
  'віспа',
  'пагін',
  'еліпс',
  'дефіс',
  'гілля',
  'мафія',
  'зеніт',
  'пікап',
  'мітла',
  'мізки',
  'зріст',
  'зефір',
  'ґроно',
  'досьє',
  'війна',
  'зілля',
  'афіша',
  'місто',
  'горіх',
  'меблі',
  'вінця',
  'віяло',
  'окріп',
  'пінка',
  'дзюдо',
  'німфа',
  'поїзд',
  'архів',
  'сусід',
  'ліана',
  'гетто',
  'драже',
  'міхур',
  'аніме',
  'фініш',
  'кінза',
  'манго',
  'вірус',
  'ґрунт',
  'талія',
  'втіха',
  'гілка',
  'сікач',
  'білка',
  'живіт',
  'цвіль',
  'гієна',
  'водій',
  'дзвін',
  'посів',
  'відео',
  'аудіо',
  'іскра',
  'рубін',
  'табло',
  'іспит',
  'бійня',
  'чобіт',
  'мітка',
  'девіз',
  'рідня',
  'рубіж',
  'сепія',
  'какао',
  'бійка',
  'лівер',
  'вихід',
  'ікона',
  'віщун',
  'стіна',
  'трико',
  'шепіт',
  'боїнг',
  'серія',
  'батіг',
  'пісок',
  'еліта',
  'кішка',
  'хутір',
  'нація',
  'євнух',
  'іслам',
  'ліжко',
  'захід',
  'похід',
  'бенді',
  'камін',
  'віраж',
  'рушій',
  'коргі',
  'надія',
  'таксі',
  'суфле',
  'бінго',
  'олімп',
  'кріль',
  'руїна',
  'бісер',
  'напій',
  'кілок',
  'лінза',
  'алібі',
  'фікус',
  'комік',
  'дієта',
  'імбир',
  'лідер',
  'кольє',
  'імідж',
  'суміш',
  'хміль',
  'лійка',
  'пілон',
  'фінік',
  'слоїк',
  'вічко',
  'калій',
  'двері',
  'зміст',
  'білет',
  'ціпок',
  'мідяк',
  'сітка',
  'вілла',
  'виріз',
  'армія',
  'рупія',
  'загін',
  'фізик',
  'бідон',
  'вечір',
  'табір',
  'фірма',
  'січка',
  'теніс',
  'пірат',
  'мідія',
  'біляш',
  'вівця',
  'флірт',
  'диско',
  'хвіст',
  'круїз',
  'ізгой',
  'гамір',
  'літак',
  'амінь',
  'вітер',
  'діжка',
  'адмін',
  'річка',
  'докір',
  'сесія',
  'ліцей',
  'гірка',
  'зірка',
  'індус',
  'плеєр',
  'рація',
  'пиріг',
  'сідло',
  'куліш',
  'віник',
  'дербі',
  'заєць',
  'осінь',
  'пішак',
  'виїзд',
  'утіха',
  'кефір',
  'вікно',
  'хаскі',
  'плато',
  'опіум',
  'пікет',
  'друїд',
  'пітон',
  'нетрі',
  'гість',
  'сокіл',
  'лінія',
  'ґрати',
  'ранчо',
  'ескіз',
  'яєчко',
  'квіти',
  'вішак',
  'вінок',
  'лікар',
  'геній',
  'аміак',
  'ніжка',
  'візок',
  'акція',
  'індик',
  'дівка',
  'бігль',
  'радіо',
  'аґрус',
  'кіоск',
  'ріпак',
  'ґедзь',
  'боєць',
  'місія',
  'цинік',
  'гелій',
  'єресь',
  'мінус',
  'дзиґа',
  'інжир',
  'віскі',
  'вініл',
  'дірка',
  'міраж',
  'фінал',
  'ліміт',
  'жінка',
  'бігун',
  'дрізд',
  'зомбі',
  'білок',
  'папір',
  'дівча',
  'хімік',
  'комір',
  'біржа',
  'мішок',
  'пісня',
  'шкіра',
  'хімія',
  'білль',
  'печія',
  'напір',
  'нірка',
  'ретро',
  'болід',
  'кредо',
  'фобія',
  'гроші',
  'успіх',
  'фільм',
  'родео',
]
